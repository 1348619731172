import StyleContainer from './style';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useRef } from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import AnimatedProgressProvider from '../../utils/animatedProgressProvider';
import { easeLinear } from 'd3-ease';

function GameTip(props) {
  const { joinWaitSeconds, joinWaitRemaining } = props;
  const { t } = useTranslation();
  const tips = ['toggleRandom', 'adjustSound', 'numbers', 'thumbs', 'tieWin', 'disconnected', 'prizeBox'];
  const [ctr, setCtr] = useState(Math.floor(Math.random() * tips.length));
  const dur = useRef(-1);
  const delay = 10;

  useEffect(() => {
    const nextTip = () => {
      let n = Math.floor(Math.random() * tips.length);
      if(ctr === n) n = (n + 1) % tips.length;
      setCtr(n);
    };
    
    let timer;
    if(joinWaitRemaining && dur.current < 0){
      dur.current = joinWaitRemaining;
      nextTip();
    }else{
      timer = setTimeout(() => {
        dur.current = Math.max(0, dur.current - delay);
        nextTip();
      }, delay * 1000);
    }
    return () => {
      if(timer) clearTimeout(timer);
    }
  }, [ctr, tips.length, joinWaitSeconds, joinWaitRemaining]);

  return(
    <StyleContainer>
      <h1>{t('gameTip')}</h1>
      <div className='tip'>{t(tips[ctr])}</div>
      {dur.current !== -1 && <div className='circle'>
        <AnimatedProgressProvider
          valueStart={joinWaitRemaining}
          valueEnd={0}
          duration={dur.current}
          easingFunction={easeLinear}
        >
          {(value) => {
            const roundedValue = Math.ceil(value).toString().padStart(2, '0');
            return (
              <CircularProgressbarWithChildren
                value={value}
                minValue={0}
                maxValue={joinWaitSeconds}
                counterClockwise={true}
                styles={buildStyles({ 
                  pathTransition: 'none',
                  pathColor: '#ff9933',
                  trailColor: '#f0f0f0',
                  text: {
                    y: 70
                  }
                })}
              >
                <div className='circle-text'>{`${roundedValue}`}</div>
              </CircularProgressbarWithChildren>
            );
          }}
        </AnimatedProgressProvider>
      </div>}
    </StyleContainer>
  );
}

const mapState = state => {
	return {
		joinWaitSeconds: state.cards.joinWaitSeconds,
		joinWaitRemaining: state.cards.joinWaitRemaining
	};
}

export default connect(mapState)(GameTip);
