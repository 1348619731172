import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toggleInstructions } from '../../actions';
import {
    StyleContainer,
    InstructionFirstScreen,
    InstructionNextBtn,
    InstructionNextBtnContainer,
    InstructionScreen1,
    InstructionScreen2,
    InstructionScreen3,
    InstructionScreen4,
    InstructionScreen5,
    InstructionScreen6,
    InstructionClose,
} from './style';

function Instructions() {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const [activePage, setActivePage] = useState(1);
    const goToPage = (page) => {
        setActivePage(page);
    };

    const handleToggleInstructions = () => dispatch(toggleInstructions());

    const getInstructionScreenByPage = (page) => {
        switch (page) {
            case 1:
                return <InstructionScreen1 lang={i18n.language} />;
            case 2:
                return <InstructionScreen2 lang={i18n.language} />;
            case 3:
                return <InstructionScreen3 lang={i18n.language} />;
            case 4:
                return <InstructionScreen4 lang={i18n.language} />;
            case 5:
                return <InstructionScreen5 lang={i18n.language} />;
            default:
                return <InstructionScreen6 lang={i18n.language} />;
        }
    };

    const displayPageInstructionsByPage = (page) => {
        return (
            <>
                {getInstructionScreenByPage(page)}
                <InstructionNextBtnContainer>
                    <InstructionNextBtn
                        onClick={() => {
                            if (page !== 6) {
                                goToPage(page + 1);
                                return;
                            }

                            handleToggleInstructions();
                        }}
                    >
                        {t('gotIt')}
                    </InstructionNextBtn>
                </InstructionNextBtnContainer>
            </>
        );
    };

    const DisplayPages = () => {
        switch (activePage) {
            case 1:
                return displayPageInstructionsByPage(1);
            case 2:
                return displayPageInstructionsByPage(2);
            case 3:
                return displayPageInstructionsByPage(3);
            case 4:
                return displayPageInstructionsByPage(4);
            case 5:
                return displayPageInstructionsByPage(5);
            default:
                return displayPageInstructionsByPage(6);
        }
    };

    return (
        <StyleContainer>
            <InstructionFirstScreen>
                <InstructionClose onClick={handleToggleInstructions}>
                    ✕
                </InstructionClose>
                <DisplayPages />
            </InstructionFirstScreen>
        </StyleContainer>
    );
}

export default Instructions;
