import styled from '@emotion/styled';
import { Logo } from '../../assets/images';

const StyleContainer = styled.div`
  background: url(${Logo}) center / contain;
  width: 455px;
  height: 295px;
  margin-top: -72px;
  margin-bottom: -79px;
`;

export default StyleContainer;