import { io } from 'socket.io-client';
import { 
  GAME_PLAYER_COMPLETE, 
  GAME_PLAYER_JOIN, 
  GAME_PLAYER_WAITING, 
  GAME_ERROR,
  SET_GAME,
  GAME_OVER,
  GAME_READY,
  GAME_PICK_NEXT,
  OPPONENT_PICK_NEXT,
  GAME_PICK,
  GAME_PROGRESS,
  GAME_WON,
  GAME_TIE,
  GAME_TIE_PICK,
  GAME_TIE_PICK_NEXT,
  OPPONENT_TIE_PICK_NEXT,
  GAME_TIE_PROGRESS,
  GAME_TIE_PENDING,
  GAME_TIE_WON,
  PICK_BALL,
  GAME_CONNECT,
  GAME_CANCEL,
  GAME_ROOM_JOIN,
  GAME_ROOM_JOIN_COMPLETE,
  GAME_ROOM_JOIN_WAITING,
  setPlayer,
  setPrize,
  updateCards,
  drawBall,
  opponentTurn,
  gameOver,
  gameComplete,
  setGame,
  revealBall,
  warning,
  rejoinRoom,
  updateTurn,
  setAll
} from '../actions';

const getParams = () => {
  return new URLSearchParams(window.location.search.slice(1));
}

const logger = (event, data) => {
  //console.log(event, data);
}

const socketMiddleware = store => {
  let gameInfo = {};
  let hasPicked = false;

  const socket = io({
    transports: ['websocket', 'polling'],
    autoConnect: false
  });

  socket.on('connect', () => {
    logger('connect', gameInfo.gameSessionId);
    if(gameInfo.gameSessionId)
      store.dispatch(rejoinRoom(gameInfo.gameSessionId, gameInfo.playerSessionId));
    else
      store.dispatch(setGame());
  });

  socket.on('disconnect', () => {
    logger('disconnect');
  });

  socket.on(GAME_PLAYER_WAITING, payload => {
    logger(GAME_PLAYER_WAITING, payload);
    store.dispatch(setPlayer(payload));
  });

  socket.on(GAME_PLAYER_COMPLETE, payload => {
    logger(GAME_PLAYER_COMPLETE, payload);
    store.dispatch(setPlayer(payload));
  });

  socket.on(GAME_READY, payload => {
    logger(GAME_READY, payload);
    gameInfo = Object.assign({}, payload);
    store.dispatch(setPrize(payload.prizeChoices, payload.gamePrize, payload.pickTimeOutSeconds));
  });

  socket.on(GAME_PICK_NEXT, payload => {
    logger(GAME_PICK_NEXT, payload);
    gameInfo = Object.assign({}, gameInfo, payload, {started: true});
    hasPicked = false;
    store.dispatch(drawBall(payload.playerAlias, payload.numberOfBallChoices, payload.pickTimeOutSeconds, false));
  });

  socket.on(OPPONENT_PICK_NEXT, payload => {
    logger(OPPONENT_PICK_NEXT, payload);
    gameInfo = Object.assign({}, gameInfo, payload, {started: true});
    hasPicked = false;
    store.dispatch(drawBall(payload.opponentTurn.playerAlias, payload.numberOfBallChoices, payload.pickTimeOutSeconds, false));
  });

  const revealAndUpdate = (payload) => {
    hasPicked = true;
    store.dispatch(revealBall(payload.ballChoices, payload.ballIndex, payload.whoPicked, payload.self, payload.opponents));
    setTimeout(() => {
      store.dispatch(updateCards(payload.self, payload.opponents, payload.ballCalls));
    }, 2000);
  };

  socket.on(GAME_PROGRESS, payload => {
    logger(GAME_PROGRESS, payload);
    gameInfo = Object.assign({}, gameInfo, payload);
    const isTurboDraw = getParams().get('style') === 'TURBODRAW';
    if(isTurboDraw){
      store.dispatch(drawBall('', 3, 0, false));
    }
    setTimeout(() => {
      revealAndUpdate(payload);
    }, isTurboDraw ? (gameInfo?.pickTimeOutSeconds || 3) * 1000 : 0);
  });

  socket.on(GAME_WON, payload => {
    logger(GAME_WON, payload);
    gameInfo = Object.assign({}, gameInfo, payload);
    revealAndUpdate(payload);
    setTimeout(() => {
      store.dispatch(gameComplete(payload.winner));
    }, 4500);
  });

  socket.on(GAME_TIE, payload => {
    logger(GAME_TIE, payload);
    gameInfo = Object.assign({}, gameInfo, payload, {numberOfBallChoices: 0});
    revealAndUpdate(payload);
  });

  socket.on(GAME_TIE_PICK_NEXT, payload => {
    logger(GAME_TIE_PICK_NEXT, payload);
    gameInfo = Object.assign({}, gameInfo, payload);
    hasPicked = false;
    store.dispatch(drawBall(payload.playerAlias, payload.numberOfBallChoices, payload.pickTimeOutSeconds, true));
  });

  socket.on(OPPONENT_TIE_PICK_NEXT, payload => {
    logger(OPPONENT_TIE_PICK_NEXT, payload);
    gameInfo = Object.assign({}, gameInfo, payload);
    hasPicked = false;
    store.dispatch(opponentTurn(payload.opponentTurn));
    store.dispatch(drawBall(payload.opponentTurn.playerAlias, gameInfo.numberOfBallChoices, 0, true));
  });

  socket.on(GAME_TIE_PROGRESS, payload => {
    logger(GAME_TIE_PROGRESS, payload);
    gameInfo = Object.assign({}, gameInfo, payload);
    hasPicked = true;
    store.dispatch(revealBall(payload.ballNumber, payload.revealIndex, payload.whoPicked, payload.self, payload.opponents, true));
  });

  socket.on(GAME_TIE_PENDING, payload => {
    logger(GAME_TIE_PENDING, payload);
    gameInfo = Object.assign({}, gameInfo, payload);
    hasPicked = true;
    store.dispatch(updateTurn(payload.tieBreaker));
  });

  socket.on(GAME_TIE_WON, payload => {
    logger(GAME_TIE_WON, payload);
    gameInfo = Object.assign({}, gameInfo, payload);
    hasPicked = true;
    store.dispatch(revealBall(payload.ballNumber, payload.revealIndex, payload.whoPicked, payload.self, payload.opponents));
    setTimeout(() => {
      store.dispatch(gameComplete(payload.winner));
    }, 1000);
  });

  socket.on(GAME_OVER, payload => {
    logger(GAME_OVER, payload);
    gameInfo = Object.assign({}, gameInfo, payload, {started: false});
    store.dispatch(gameOver(payload));
  });

  socket.on(GAME_ERROR, payload => {
    logger(GAME_ERROR, payload);
    store.dispatch(warning(payload['error.key'], !gameInfo.started));
  });

  socket.on(GAME_CANCEL, payload => {
    logger(GAME_CANCEL, payload);
    gameInfo.started = false;
    store.dispatch(warning(payload['cancel.message'], true));
  });

  socket.on(GAME_ROOM_JOIN_COMPLETE, payload => {
    logger(GAME_ROOM_JOIN_COMPLETE, payload);
    store.dispatch(setAll(payload));
  });

  socket.on(GAME_ROOM_JOIN_WAITING, payload => {
    logger(GAME_ROOM_JOIN_WAITING, payload);
    store.dispatch(setPlayer(payload));
  });

  const getBallIndex = (tie, ballIndex) => {
    if(gameInfo.numberOfBallChoices === 1){
      return 0;
    }else if(gameInfo.numberOfBallChoices === 2 && tie & gameInfo.revealIndex < ballIndex){
      return ballIndex - 1;
    }else{
      return ballIndex;
    }
  }

  return next => action => {
    switch(action.type){
      case GAME_CONNECT:
        gameInfo.started = false;
        if(action.gameSessionId){
          gameInfo.gameSessionId = action.gameSessionId;
          gameInfo.playerSessionId = action.playerSessionId;
        }
        !socket.connected ? socket.connect() : store.dispatch(setGame());
        break;
      case SET_GAME:
        const params = getParams();
        socket.emit(GAME_PLAYER_JOIN, {
          gameId: params.get('game'),
          buyInId: params.get('buyInId'),
          playerUserId: params.get('userId')
        });
        break;
      case PICK_BALL:
        if(!hasPicked){
          hasPicked = true;
          socket.emit(action.tie ? GAME_TIE_PICK : GAME_PICK, {
            gameSessionId: gameInfo.gameSessionId,
            playerSessionId: action.playerSessionId,
            callNumber: gameInfo.callNumber,
            ballIndex: getBallIndex(action.tie, action.ballIndex)
          });
        }
        break;
      case GAME_ROOM_JOIN: 
        socket.emit(GAME_ROOM_JOIN, {
          gameSessionId: gameInfo.gameSessionId,
          playerSessionId: gameInfo.playerSessionId
        });
        break;
      default:
        break;
    }
    next(action);
  }
}
 
export default socketMiddleware;