import React, { useRef, useState } from 'react';

const DataContext = React.createContext(null);

export { DataContext };

const DataContextContainer = (props) => {
  const currency = useRef('');
  const playerCurrency = useRef('');
  const currencies = useRef({});
  const balance = useRef(0);
  const [bet, setBet] = useState(0);

  return(
    <DataContext.Provider value={{ currency, playerCurrency, currencies, balance, bet, setBet }}>
      { props.children }
    </DataContext.Provider>
  );
};

export default DataContextContainer;