import StyleContainer from './style';
import { connect } from 'react-redux';
import { warning } from '../../actions';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { DataContext } from '../../contexts/DataContextContainer';

function Warning(props) {
  const { warning, warningMsg, cancel } = props;
  const navigate = useNavigate();
  const { setBet } = useContext(DataContext);
  const { t } = useTranslation();

  const handleOk = () => {
    warning('', false);
    const params = new URLSearchParams(window.location.search.slice(1));
    if(cancel){
      setBet(0);
      navigate('/lobby?' + params.toString(), {replace: true});
    }
  }

  if(warningMsg){
    return(
      <StyleContainer>
        <div className='modal'>
          <div className='warning'></div>
          <h1>{t('errorMsg')}</h1>
          <p>{t(warningMsg)}</p>
          <code>({warningMsg})</code>
          <div className='btn btn--red' onClick={handleOk}>{t('ok')}</div>
        </div>
      </StyleContainer>
    );
  }
  return null;
}

const mapDispatch = dispatch => {
	return {
		warning: msg => dispatch(warning(msg)),
	};
}

const mapState = state => {
	return {
    warningMsg: state.cards.warningMsg,
    cancel: state.cards.cancel,
	};
}

export default connect(mapState, mapDispatch)(Warning);
