/* action types */

export const RESET = 'RESET';
export const DRAW_BALL = 'DRAW_BALL';
export const OPPONENT_TURN = 'OPPONENT_TURN';
export const PICK_BALL = 'PICK_BALL';
export const TOGGLE_RANDOM = 'TOGGLE_RANDOM';
export const UPDATE_CARDS = 'UPDATE_CARDS';
export const SET_PLAYER = 'SET_PLAYER';
export const GAME_COMPLETE = 'GAME_COMPLETE';
export const SET_PRIZE = 'SET_PRIZE';
export const SET_GAME = 'SET_GAME';
export const TOGGLE_MUSIC = 'TOGGLE_MUSIC';
export const TOGGLE_SOUND = 'TOGGLE_SOUND';
export const TOGGLE_INSTRUCTIONS = "TOGGLE_INSTRUCTIONS";
export const CHANGE_VOLUME = 'CHANGE_VOLUME';
export const REVEAL_BALL = 'REVEAL_BALL';
export const WARNING = 'WARNING';
export const UPDATE_TURN = 'UPDATE_TURN';
export const SET_ALL = 'SET_ALL';

export const GAME_CONNECT = 'game_connect';
export const GAME_PLAYER_JOIN = 'game_player_join';
export const GAME_PLAYER_WAITING = 'game_player_join_waiting';
export const GAME_PLAYER_COMPLETE = 'game_player_join_complete';
export const GAME_ERROR = 'game_error';
export const GAME_READY = 'game_ready';
export const GAME_PICK_NEXT = 'game_pick_next';
export const OPPONENT_PICK_NEXT = 'opponent_pick_next';
export const GAME_PICK = 'game_pick';
export const GAME_PROGRESS = 'game_progress';
export const GAME_WON = 'game_won';
export const GAME_TIE = 'game_tie';
export const GAME_TIE_PICK_NEXT = 'game_tie_pick_next';
export const OPPONENT_TIE_PICK_NEXT = 'opponent_tie_pick_next';
export const GAME_TIE_PICK = 'game_tie_pick';
export const GAME_TIE_PROGRESS = 'game_tie_progress';
export const GAME_TIE_PENDING = 'game_tie_pending';
export const GAME_TIE_WON= 'game_tie_won';
export const GAME_OVER = 'game_over';
export const GAME_CANCEL = 'game_cancel';
export const GAME_ROOM_JOIN = 'game_room_join';
export const GAME_ROOM_JOIN_COMPLETE = 'game_room_join_complete';
export const GAME_ROOM_JOIN_WAITING = 'game_room_join_waiting';

/* action creators */

export function reset(){
  return {type: RESET};
}

export function updateCards(self, opponents, ballCalls){
  return {type: UPDATE_CARDS, self, opponents, ballCalls};
}

export function setPlayer(payload){
  return {type: SET_PLAYER, payload};
}

export function drawBall(playerAlias, numberOfBallChoices, pickTimeOut, tie){
  return {type: DRAW_BALL, playerAlias, numberOfBallChoices, pickTimeOut, tie};
}

export function opponentTurn(opponent){
  return {type: OPPONENT_TURN, opponent};
}

export function pickBall(ballIndex, tie, playerSessionId){
  return {type: PICK_BALL, ballIndex, tie, playerSessionId};
}

export function toggleRandom(){
  return {type: TOGGLE_RANDOM};
}

export function gameComplete(winnerId){
  return {type: GAME_COMPLETE, winnerId};
}

export function setPrize(prizeChoices, gamePrize, pickTimeOut){
  return {type: SET_PRIZE, prizeChoices, gamePrize, pickTimeOut};
}

export function setGame(){
  return {type: SET_GAME};
}

export function toggleMusic(){
  return {type: TOGGLE_MUSIC};
}

export function toggleSound(){
  return {type: TOGGLE_SOUND};
}

export function toggleInstructions(){
  return {type: TOGGLE_INSTRUCTIONS};
}

export function changeVolume(vol){
  return {type: CHANGE_VOLUME, vol};
}

export function gameOver(payload){
  return {type: GAME_OVER, payload};
}

export function gameConnect(gameSessionId, playerSessionId){
  return {type: GAME_CONNECT, gameSessionId, playerSessionId};
}

export function revealBall(ballNumber, ballIndex, whoPicked, self, opponents, tie){
  return {type: REVEAL_BALL, ballNumber, ballIndex, whoPicked, self, opponents, tie};
}

export function warning(msg, cancel){
  return {type: WARNING, msg, cancel};
}

export function rejoinRoom(gameSessionId, playerSessionId){
  return {type: GAME_ROOM_JOIN, gameSessionId, playerSessionId};
}

export function updateTurn(tieBreaker){
  return {type: UPDATE_TURN, tieBreaker};
}

export function setAll(payload){
  return {type: SET_ALL, payload}
}
