import styled from '@emotion/styled';
import { BgPrize } from '../../assets/images';

const StyleContainer = styled.div`
  left: 0;
  top: 10px;
  visibility: ${props => props.visible ? 'visible' : 'hidden'};
  border-radius: 110px;
  width: 721px;
  height: 212px;
  transform: scale(0.25);
  margin: -80px -270px;
  background: url(${BgPrize}) center / contain no-repeat;
  position: relative;
  box-shadow: 0 6px 8px rgba(51, 59, 69, 0.37);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 120px;
  font-weight: 700;
  color: #ffd800;
  text-align: center;
  text-shadow: 1px -1px 1px #c9800c, 0 2px 0 #b36a06, 0 2px 4px #000;
  flex-shrink: 0;
  
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 110px;
    background-color: ${props => props.animate ? '#fff' : 'transparent'};
    animation: ${props => props.animate && props.visible ? 'fade-white 0.5s ease-in forwards' : 'none'};
  }

  @keyframes fade-white {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }

  @media only screen and (max-height: 750px) {
    transform: scale(0.2);
  }
`;

export default StyleContainer;