import styled from '@emotion/styled';
import { BtnOn, BtnOff } from '../../assets/images';

const StyleContainer = styled.div`
  position: absolute;
  bottom: 15px;
  height: 20px;
  display: flex;
  flex-direction: row;
  margin: 0 10px;

  .btn {
    cursor: pointer;
  }
  
  .btn:active {
    opacity: 0.25; 
  }

  .pill {
    width: 48px;
    height: 20px;
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-size: 10px;
    font-weight: 700;
    padding-top: 3px;
    text-align: center;
  }

  .pill--on {
    background: url(${BtnOn}) center / contain no-repeat;
    color: #2b3564;
    text-shadow: 0px 1px 0 #b1ff81;
    padding-left: 5px;
    padding-right: 16px;
  }

  .pill--off {
    background: url(${BtnOff}) center / contain no-repeat;
    color: #333;
    text-shadow: 0px 1px 0 #999;
    padding-left: 16px;
    padding-right: 5px;
  }

  .text {
    font-size: 12px;
    color: #fff;
    margin: 3px 6px;
  }

  @media only screen and (max-height: 670px) {
    bottom: 10px;
  }
`;

export default StyleContainer;