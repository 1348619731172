import styled from '@emotion/styled';
import { Bulb, Crosshair } from '../../assets/images';

const StyleContainer = styled.div`
  position: relative;
  width: 460px;
  height: 300px;
  border-radius: 10px;
  box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.1);
  background: #f9fbfc url(${Bulb}) 317px 19px / 41px 44px no-repeat;
  padding: 23px 40px 0;
  margin: 20px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  flex-shrink: 0;

  h1 {
    font-size: 36px;
    font-weight: 400;
    text-align: center;
    color: #323e48;
    margin-bottom: 9px;
  }

  .tip {
    width: 100%;
    height: 74px;
    font-size: 18px;
    text-align: center;
    color: #323e48;
  }

  .circle {
    width: 136px;
    height: 136px;
    background: url(${Crosshair}) center / 100px auto no-repeat;
    border-radius: 50%;
    box-shadow: 0px 5px 10px 0 rgba(35, 46, 66, 0.3);
  }

  .circle-text {
    font-size: 50px;
    font-weight: 700;
    fill: #232e42;
  }
`;

export default StyleContainer;