import StyleContainer from './style';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import GameTip from '../GameTip';
import Player from '../Player';
import { useEffect } from 'react';
import { gameConnect } from '../../actions';
import { useNavigate, useLocation } from 'react-router-dom';

function WaitScreen(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { gamePrize, gameConnect } = props;
  const { t } = useTranslation();

  useEffect(() => {
    gameConnect(location.state?.gameSessionId || '', location.state?.playerSessionId || '');
  }, []);

  useEffect(() => {
    if(Number(gamePrize) > 0){
      const params = new URLSearchParams(window.location.search.slice(1));
      const query = `/game?${params.toString()}`;
      navigate(query, {replace: true});
    }
  }, [gamePrize, navigate]);

  return(
    <StyleContainer>
      <div className='container'>{t('waitOthers')}</div>
      <GameTip />
      <Player id={0} />
      <div className='opponents'>
        <Player id={1} />
        <Player id={2} />
      </div>
    </StyleContainer>
  );
}

const mapDispatch = dispatch => {
	return {
    gameConnect: (gameSessionId, playerSessionId) => dispatch(gameConnect(gameSessionId, playerSessionId))
	};
}

const mapState = state => {
	return {
    gamePrize: state.dispenser.gamePrize
	};
}

export default connect(mapState, mapDispatch)(WaitScreen);