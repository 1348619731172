import { useEffect, useRef, useState } from "react";
import { Animate } from "react-move";

const AnimatedProgressProvider = (props) => {
  const { repeat, duration, valueStart, valueEnd, easingFunction, children } = props;
  let interval = useRef();
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    if (repeat) {
      interval.current = window.setInterval(() => {
        setIsAnimated(!isAnimated);
      }, duration * 1000);
    } else {
      setIsAnimated(!isAnimated);
    }
    return () => {
      window.clearInterval(interval.current);
    }
  }, []);

  return (
    <Animate
      start={() => ({
        value: valueStart
      })}
      update={() => ({
        value: [
          isAnimated ? valueEnd : valueStart
        ],
        timing: {
          duration: duration * 1000,
          ease: easingFunction
        }
      })}
    >
      {({ value }) => children(value)}
    </Animate>
  );
};

export default AnimatedProgressProvider;
