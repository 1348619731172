import StyleContainer from './style';
import useFitText from 'use-fit-text';

function TextResizer(props) {
  const {fontSize, ref} = useFitText({ maxFontSize: props.fontSize });

  return(
    <StyleContainer ref={ref} style={{fontSize, ...props.style}}>
      {props.text}
    </StyleContainer>
  );
}

export default TextResizer;
