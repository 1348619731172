import styled from '@emotion/styled';

const StyleContainer = styled.div`
  width: 100vw;
  position: fixed;

  .bg-video {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: fixed;
  }
`;

export default StyleContainer;