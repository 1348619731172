import StyleContainer from './style';
import { connect, useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';

function MessageBar(props) {
  const { currentTurn, pickIndex } = props;
  const store = useStore();
  const tie = store.getState().cards.tieBreaker.length > 0;
  const players = store.getState().cards.players;
  const { t } = useTranslation();
  const sPick = t('pickBall');
  let style = 'fade-in';

  if(!currentTurn || pickIndex === -1){
    style = 'fade-out';
	}

  if(players[0].playerTurn === currentTurn || tie) style += ' me';

  const getName = () => {
    if(players[0].playerTurn === currentTurn){
      return sPick;
    }else{
      return t('takeTurn', { name: players.find(value => value.playerTurn === currentTurn).playerAlias });
    }
  };

  if(currentTurn){
    return(
      <StyleContainer className={style} tie={tie}>
        <div>{getName()}</div>
        {getName() !== sPick && <div className='dots'></div>}
      </StyleContainer>
    );
  }else{
    return null;
  }
}

const mapState = state => {
  return {
    currentTurn: state.cards.currentTurn,
    pickIndex: state.dispenser.pickIndex,
  };
}

export default connect(mapState)(MessageBar);
