import StyleContainer from './style';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Players from '../Players';
import PrizeCard from '../PrizeCard';
import Dispenser from '../Dispenser';
import RandomPlay from '../RandomPlay';
import Chest from '../Chest';
import Badge from '../Badge';
import { gameConnect } from '../../actions';
import { useEffect } from 'react';

function Game(props) {
  const { gameConnect } = props;
  const location = useLocation();

  useEffect(() => {
    if(typeof location.state?.gameSessionId === 'string' || location.state?.gameSessionId.toString().length > 0) gameConnect(location.state.gameSessionId, location.state.playerSessionId);
  }, []);

  return(
    <StyleContainer>
      <PrizeCard animate={true} />
      <Players />
      <Dispenser />
      <RandomPlay />
      <Chest />
      <Badge />
    </StyleContainer>
  );
}

const mapDispatch = dispatch => {
	return {
    gameConnect: (gameSessionId, playerSessionId) => dispatch(gameConnect(gameSessionId, playerSessionId))
	};
}

export default connect(null, mapDispatch)(Game);
