import { useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Logo from '../Logo';
import { warning, reset } from '../../actions';
import { DataContext } from '../../contexts/DataContextContainer';
import StyleContainer from './style';
import Instructions from '../Instructions';
import Currency from '../../utils/currency';

function BuyIn() {
    const { isInstructions } = useSelector((state) => state.menu);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const resData = useRef();
    const buyIn = useRef([]);
    const params = useMemo(
        () => new URLSearchParams(window.location.search.slice(1)),
        [],
    );
    const { currency, playerCurrency, currencies, balance, bet, setBet } =
        useContext(DataContext);
    const { t } = useTranslation();

    const getSessionId = useCallback(() => params.get('sessionId'), [params]);

    const getTheme = useMemo(
        () => params.get('theme') || resData.current?.gameTheme,
        [params],
    );

    const getBuyInId = useCallback(() => {
        return buyIn.current.find((value) => value.amount === bet)?.id || -1;
    }, [bet]);

    const handlePlay = useCallback(
        (gameData) => {
            const query = `game=${resData.current.gameId}&sessionId=${getSessionId()}&buyInId=${getBuyInId()}&userId=${resData.current.player.userId}&theme=${getTheme}&style=${resData.current.gameStyle}&language=${params.get('language') || 'en'}`;
            navigate(
                (resData.current.gameStyle === 'YOUPICKEM'
                    ? '/game?'
                    : '/wait?') + query,
                {
                    replace: true,
                    state: {
                        gameSessionId: gameData?.gameSessionId || '',
                        playerSessionId: gameData?.playerSessionId || '',
                    },
                },
            );
        },
        [navigate, getSessionId, getBuyInId, getTheme, params],
    );

    const handleMinus = () => {
        const i = buyIn.current.findIndex((value) => value.amount === bet);
        setBet(buyIn.current[i - 1].amount);
    };

    const handleAdd = () => {
        const i = buyIn.current.findIndex((value) => value.amount === bet);
        setBet(buyIn.current[i + 1].amount);
    };

    const getTopPrize = () => {
        return (
            buyIn.current.find((value) => value.amount === bet).topPrize ||
            100000
        );
    };

    const parseCurrency = (n) => {
        return currencies.current[resData.current.currency].draw(
            n,
            resData.current.player.playerBalance.currency,
        );
    };

    const minBuy = () => {
        return bet === buyIn.current[0].amount;
    };

    const maxBuy = () => {
        const i = buyIn.current.findIndex((value) => value.amount === bet);
        return (
            i === buyIn.current.length - 1 ||
            (i < buyIn.current.length - 1 &&
                balance.current < buyIn.current[i + 1].amount)
        );
    };

    useEffect(() => {
        dispatch(reset());
    }, [dispatch]);

    useEffect(() => {
        const options = {
            method: 'GET',
            headers: {
                'x-session-token': getSessionId(),
            },
        };

        const checkActiveGame = (data) => {
            fetch(
                `api/admin/games/${params.get('game')}/players/${data.player.userId}`,
                options,
            )
                .then((response) => {
                    if (response.status === 200) return response.json();
                    else return null;
                })
                .then((gameData) => {
                    resData.current = data;
                    buyIn.current = data.buyins.slice().map((value) => {
                        value.amount = parseFloat(value.amount);
                        return value;
                    });
                    data.currencies.forEach((currency) => {
                        currencies.current[currency.code] = new Currency(
                            currency.code,
                            { convert: {}, ...currency.format },
                        );
                    });
                    currency.current = data.currency;
                    playerCurrency.current = data.player.playerBalance.currency;
                    balance.current = Number(data.player.playerBalance.total);
                    setBet(buyIn.current[0].amount);
                    if (gameData) handlePlay(gameData);
                });
        };

        fetch(`/api/admin/games/${params.get('game') || 'SG1'}/client`, options)
            .then((response) => {
                if (!response.ok) dispatch(warning(response.statusText));
                return response.json();
            })
            .then((data) => {
                checkActiveGame(data);
            });
    }, [
        balance,
        currencies,
        currency,
        dispatch,
        handlePlay,
        getSessionId,
        setBet,
        params,
        playerCurrency,
    ]);

    if (resData.current) {
        return (
            <StyleContainer>
                <div className="buy-in-bg">
                    <Logo />
                    <h1>{t(resData.current.gameStyle.toLowerCase())}</h1>
                    <h2>{t('winUpTo')}</h2>
                    <div className="buy-in-prize">
                        {parseCurrency(getTopPrize()).split('.')[0]}
                    </div>
                    <h3>{t('buyIn')}</h3>
                    <div className="bet">
                        <div
                            className={'btn btn--minus ' + (minBuy() && 'hide')}
                            onClick={handleMinus}
                        ></div>
                        <div className="buy-in-bet">{parseCurrency(bet)}</div>
                        <div
                            className={'btn btn--add ' + (maxBuy() && 'hide')}
                            onClick={handleAdd}
                        ></div>
                    </div>
                    <div className="btn btn--purple" onClick={handlePlay}>
                        {t('playNow')}
                    </div>
                </div>
                {isInstructions && <Instructions />}
            </StyleContainer>
        );
    }
    return null;
}

export default BuyIn;
