import styled from '@emotion/styled';
import { Silhouette } from '../../assets/images';

const StyleContainer = styled.div`
  position: relative;
  color: #fff;
  flex-shrink: 0;

  &.self {
    width: 360px;
    height: 50px;

    > .player-name {
      width: 100%;
      height: 100%;
      border-radius: 25px;
      background-color: #4a8b2c;
    }
  }

  &.opponent {
    width: 220px;
    height: 290px;

    > .player-name {
      width: 100%;
      height: 50px;
      margin: 20px 0 0 0;
      border-radius: 25px;
      background-color: rgba(35, 46, 66, 0.85);
    }
  }

  .player-name {
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .photo-box {
    position: relative;
    width: 220px;
    height: 220px;
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 10px;
    box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.1);

    &::before {
      content: '';
      position: absolute;
      left: 10px;
      top: 10px;
      width: 200px;
      height: 200px;
      background-color: rgba(255, 255, 255, 0.75);
      border-radius: 10px;
    }
  }

  .silhouette {
    position: absolute;
    width: 174px;
    height: 184px;
    left: calc((100% - 174px) / 2);
    bottom: 10px;
    background: url(${Silhouette}) center / contain no-repeat;
    font-size: 86px;
    text-align: center;
    padding: 18px 0;
  }

  .dots {
    width: 20px;
  }

  .dots::before {
    content: '';
    animation: dots 2s linear infinite;
  }

  @keyframes dots {
    0% { content: ''; }
    25% { content: '.'; }
    50% { content: '..'; }
    75% { content: '...'; }
  }
`;

export default StyleContainer;