import StyleContainer from './style';
import { useRef, useEffect } from 'react';

function Square(props) {
  const { cardId, num, drawn, latest, ballPicked, id, winner, complete } = props;
  const squareRef = useRef();
  const style = {};
  const canvasRef = useRef(null);
  let x = (cardId === 0 ? 96 : cardId === 1 ? 26 : 186) + Math.floor(id / 5) * (cardId ? 28 : 32);

  useEffect(() => {
    if(!canvasRef.current) return null;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let req;
    
    const circ = {radius: 10, fillAlpha: 1.2, strokeAlpha: 1.75};
    const stars = [];

    const reset = () => {
      const dx = 1 - Math.random() * 2;
      const dy = 1 - Math.random() * 2;
      return { x: canvas.width/2 + dx, y: canvas.height/2 + dy, speedX: dx, speedY: dy, alpha: 1.25 };
    }

    while(stars.length < 30){
      stars.push(reset());
    }

    const drawStar = (cx, cy, points, outer, inner, fill) => {
      ctx.beginPath();
      ctx.moveTo(cx, cy+outer);
      for (var i=0; i < 2*points+1; i++) {
          var r = (i%2)? inner : outer;
          var a = Math.PI * i/points;
          ctx.lineTo(cx + r*Math.sin(a), cy + r*Math.cos(a));
      };
      ctx.closePath();
      ctx.fillStyle=fill;
      ctx.fill();
    }

    const draw = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.globalAlpha = 1;
      for(let i = 0; i < stars.length; i++){
        if(stars[i].alpha <= 0) return;
        stars[i].x += stars[i].speedX;
        stars[i].y += stars[i].speedY;
        stars[i].alpha = Math.max(0, stars[i].alpha - 0.02);
        ctx.save();
        ctx.globalAlpha = stars[i].alpha;
        drawStar(stars[i].x, stars[i].y, 4, 3, 1, '#fff', '#fff', 0);
        ctx.restore();
      }
      circ.radius += 0.3;
      circ.fillAlpha = Math.max(0, circ.fillAlpha - 0.04);
      circ.strokeAlpha = Math.max(0, circ.strokeAlpha - 0.05);
      ctx.beginPath();
      ctx.arc(canvas.width/2, canvas.height/2, circ.radius, 0, 2 * Math.PI);
      ctx.fillStyle='rgba(255,255,255,' + circ.fillAlpha + ')';
      ctx.fill();
      ctx.strokeStyle='rgba(255,255,255,' + circ.strokeAlpha + ')';
      ctx.lineWidth = 2;
      ctx.stroke();

      req = requestAnimationFrame(draw);
    }

    setTimeout(() => {
      requestAnimationFrame(draw);
    }, 500);

    return () => cancelAnimationFrame(req);
  });

  if(latest){
    const rect = squareRef.current.getBoundingClientRect();
    const scale = rect.height / squareRef.current.offsetHeight;
    style.left = 23 / scale - x + 120 * ballPicked;
    style.top = (window.innerHeight - rect.y) / scale - 162;
  }
  
  return(
    <StyleContainer ref={squareRef} {...props}>
      <div className='text'>{num}</div>
      {latest && <div className='ball' style={style}></div>}
      {drawn && <div className={'full circle ' + (cardId ? 'circle--red ' : 'circle--green ') + (winner && !complete && 'circle--shine')}></div>}
      {latest && <canvas ref={canvasRef} width={200} height={200} />}
      {winner && !complete && latest && <div className='shockwave'></div>}
    </StyleContainer>
  );
}

export default Square;
