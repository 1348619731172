import styled from '@emotion/styled';

const StyleContainer = styled.div`
  width: 100%;
  height: calc(100% - 48px);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .container {
    position: relative;
    width: 460px;
    height: 50px;
    border-radius: 10px;
    background-color: rgba(25, 105, 48, 0.9);
    font-size: 24px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  .player-name {
    width: 360px;
    height: 50px;
    border-radius: 25px;
    background-color: #4a8b2c;
  }

  .opponents {
    position: relative;
    width: 460px;
    height: 29opx;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  @media only screen and (max-width: 720px), (max-height: 810px) {
    transform: scale(0.75);
  }

  @media only screen and (max-height: 667px) {
    transform: scale(0.65);
  }
`;

export default StyleContainer;