import StyleContainer from './style';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PrizeCard from '../PrizeCard';
import Square from '../Square';
import TextResizer from '../TextResizer';
import { DataContext } from '../../contexts/DataContextContainer';
import { useEffect, useContext } from 'react';
import { changeVolume } from '../../actions';
import { useTranslation } from 'react-i18next';

function Summary(props) {
  const { players, picked, winningNum, winnerId, changeVolume } = props;
  const navigate = useNavigate();
  const { setBet } = useContext(DataContext);
  const cardNum = players.findIndex(player => player.playerSessionId === winnerId);
  const player = players[cardNum];
  const params = new URLSearchParams(window.location.search.slice(1));
  const { t } = useTranslation();

  useEffect(() => {
    changeVolume(1);
  });

  const style = {
    width: '100%',
    fontWeight: '900',
    color: '#fff',
    textTransform: 'uppercase',
  };

  const handlePlay = () => {
    let query = `/lobby?${params.toString()}`;
    setBet(0);
    navigate(query, {replace: true});
  };

  const getPicks = () => {
    const p = picked.find(p => p.playerAlias === players[0].playerAlias);
    if(p.highBall)
      return p.picks.concat(p.highBall);
    else 
      return p.picks;
  }

  const Balls = p => {
    return(
      <div className='balls'>
        {p.picks.map((item, i) => {
          const own = players[0].card.some(square => square.num === item);
          return(
            <div key={i} className={'ball ' + (own ? 'circle-green ' : 'circle-red ') + (winningNum === item && ' ball--win') }>{item}</div>
          );
        })}
      </div>
    );
  }

  if(!picked.length) return null;
  
  return(
    <StyleContainer>
      <PrizeCard animate={false} />
      <div className={'header ' + (cardNum ? 'loser' : 'winner')}>
        <TextResizer fontSize={159} style={style} text={cardNum ? player.playerAlias : ''} />
        <TextResizer fontSize={315} style={style} text={cardNum ? t('won') : `${t('you')} ${t('won')}`} />
      </div>
      {cardNum !== 0 && <div className='card'>
        <div className='squares'>
          {player.card.map((item, index) => {
            const winner = player.winNum.some(num => num === item.num);
            return <Square key={index} cardId={cardNum} num={item.num} drawn={item.drawn} winner={winner} complete={true} />
          })}
        </div>
      </div>}
      <div className='summary round'>
        <TextResizer fontSize={159} style={{...style, fontWeight: '700'}} text={t('summary')} />
      </div>
      <div className='summary-box round'>
        <h3>{t('yourCard')}</h3>
        <div className='squares'>
          {players[0].card.map((item, index) => {
            return <Square key={index} cardId={0} num={item.num} drawn={item.drawn} winner={true} complete={true} />
          })}
        </div>
      </div>
      <div className='summary-box round'>
        <h3>{t('yourPicks')}</h3>
        <Balls picks={getPicks()} />
      </div>
      <div className='button btn' onClick={handlePlay}>
        <TextResizer fontSize={141} style={{width: '100%', maxHeight: '80%', fontWeight: '900', marginBottom: '5px', padding: '0 6px'}} text={t('again')} />
      </div>
    </StyleContainer>
  );
}

const mapDispatch = dispatch => {
  return {
    changeVolume: (vol) => dispatch(changeVolume(vol))
  };
}

const mapState = state => {
  return {
		players: state.cards.players,
		picked: state.cards.picked,
		winningNum: state.cards.winningNum,
    winnerId: state.cards.winnerId,
  };
}

export default connect(mapState, mapDispatch)(Summary);
