import StyleContainer from './style';
import { connect, useStore } from 'react-redux';
import { pickBall, toggleRandom } from '../../actions';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { SndBallFly, SndBallClick, SndBallReveal, SndBallRoll } from '../../assets/audios';

function Ball(props) {
  const { id, activeBall, shown, balls, pickIndex, ballsPicked, whoPicked, pickBall, toggleRandom } = props;
  const store = useStore();
  const players = store.getState().cards.players;
  const playerSessionId = store.getState().cards.players[0].playerSessionId;
  const tie = store.getState().cards.tieBreaker.length > 0;
  const currentTurn = store.getState().cards.currentTurn;
  const pickTimeOut = store.getState().dispenser.pickTimeOut;
  const timerRef = useRef(null);
  const ballRef = useRef();
  const playSound = id === 3 && store.getState().menu.playSound;
  const startTime = Date.now();
  const params = new URLSearchParams(window.location.search.slice(1));
  let styleBall = '';
  let delay = 0;

  const playSndBallClick = new Audio(SndBallClick);
  const playSndBallFly = new Audio(SndBallFly);
  const playSndBallReveal = new Audio(SndBallReveal);
  const playSndBallRoll = new Audio(SndBallRoll);

  const getIndex = () => {
    return Math.floor(id / 4);
  }

  const hasPicked = () => {
    return ballsPicked.some(value => value === getIndex() && activeBall);
  }

  const pickDispatcher = () => {
    pickBall(getIndex(), tie, playerSessionId);
  };

  const pick = activeBall && pickIndex === getIndex();
  const num = activeBall && balls.length && getIndex() < balls.length ? balls[getIndex()] : 0;
  const disable = hasPicked() || players[0].playerTurn !== currentTurn || whoPicked.some(value => value === playerSessionId);

  useEffect(() => {
    if(shown){
      if(!ballsPicked.length){
        showBall();
      }else{
        clearTimeout(timerRef.current);
        if(pick && tie && store.getState().dispenser.random && players[0].playerTurn === currentTurn){
          autoPick(0);
        }
      }
    }
  });

  useEffect(() => {
    const ballBack = () => {
      if(activeBall){
        if(playSound) playSndBallFly.play();
        if(!hasPicked() && !tie){
          gsap.set(ballRef.current, {rotation: 0});
          gsap.to(ballRef.current, {y: 0, rotation: 360, scale: 1, duration: 0.5});
        }else{
          gsap.set(ballRef.current, {autoAlpha: 0});
        }
      } 
      if(id < ballsPicked.slice(-1)[0] * 4 + 3){
        gsap.to(ballRef.current, {x: '+=30', rotation: '+=360', duration: 0.75, delay: 0.5});
      }
    }
    if(balls.length && !tie){
      setTimeout(() => {
        ballBack();
      }, 2000);
    }
  });

  const autoPick = (nDelay) => {
    timerRef.current = setTimeout(() => {
      // check ballsPicked in case random is on
      let bp = store.getState().dispenser.ballsPicked;
      if(bp.every(index => index !== getIndex())){
        pickDispatcher();
      }
    }, nDelay || 0);
  }

  const handleClick = () => {
    const curTime = Date.now();
    if(!disable && curTime < startTime + pickTimeOut * 1000 - 250){
      if(store.getState().dispenser.random) toggleRandom();
      pickDispatcher();
    }
  }

  const pickedByPlayer = () => {
    const n = ballsPicked.indexOf(getIndex());
		return whoPicked[n] === playerSessionId;
  }

  const checkWinNum = (item) => {
    return item === num;
  }

  const checkNum = (item) => {
    return item.num === num;
  }

  const showBall = () => {
    if(playSound) playSndBallRoll.play();
    gsap.to(ballRef.current, {rotation: 360, x: 30, duration: 1});
    if(activeBall){
      gsap.to(ballRef.current, {
        y: -65,
        scale: 2,
        duration: 0.3,
        delay: 1,
        onStart: () => {
          if(playSound) playSndBallReveal.play();
        },
        onComplete: () => {
          if(pick){
            if(store.getState().dispenser.random && players[0].playerTurn === currentTurn) autoPick(0);
          }
        }
      });
    }
  }

  const ToggleThumb = () => {
    if(!balls.length || tie || !activeBall){
      return null;
    }else{
      let s = 'thumb ' + (ballsPicked.slice(-1)[0] === getIndex() ? 'thumb--selected' : 'thumb--unselected');
      if(players[0].predictiveTiles.some(checkWinNum)){
        s += ' thumb--up';
      }else if(players[1].predictiveTiles.some(checkWinNum) || players[2].predictiveTiles.some(checkWinNum)){
        s += ' thumb--down';
      }else if(players[0].card.some(checkNum)){
        s += ' thumb--up';
      }else{
        s += ' thumb--down';
      }
      return <div className={s}></div>
    }
  }

  if(hasPicked()){
    if(store.getState().menu.playSound && ballsPicked.slice(-1)[0] === getIndex()) playSndBallClick.play();
    if(pickedByPlayer() || params.get('style') === 'TURBODRAW'){
      styleBall = 'ball--player';
    }
    styleBall += ' ball--light';
  }else if(params.get('style') === 'TURBODRAW') {
    if(!ballsPicked.length && !tie && activeBall) styleBall = 'ball--switch';
  }else if(!disable || (players[0].playerTurn === currentTurn && !activeBall)){
    styleBall = 'ball--player';
    if(activeBall) {
      styleBall += ' ball--glow';
      delay = tie && ballsPicked.length ? 0 : 1.3;
    }
  }else if(ballsPicked.length && !tie && players[0].playerTurn === currentTurn){
    styleBall = 'ball--player';
  }

  return(
    <StyleContainer onClick={handleClick} id={getIndex()} ref={ballRef} delay={delay} pickTimeOut={pickTimeOut - delay} tie={tie}>
      {!disable && activeBall && <CircularProgressbar strokeWidth={2} />}
      <div className={'ball ' + styleBall}>
        {((ballsPicked.length && !tie) || hasPicked()) && num ? <div className='text'>{num}</div> : <div></div>}
      </div>
      <ToggleThumb />
    </StyleContainer>
  );
}

const mapDispatch = dispatch => {
  return {
    pickBall: (index, tie, playerSessionId) => dispatch(pickBall(index, tie, playerSessionId)),
    toggleRandom: () => dispatch(toggleRandom()),
  };
}

const mapState = state => {
  return {
    balls: state.dispenser.balls,
    ballsPicked: state.dispenser.ballsPicked,
    whoPicked: state.dispenser.whoPicked,
    pickIndex: state.dispenser.pickIndex,
  };
}

export default connect(mapState, mapDispatch)(Ball);
