import StyleContainer from './style';
import { connect } from 'react-redux';
import { useContext } from 'react';
import { DataContext } from '../../contexts/DataContextContainer';

function PrizeCard(props) {
    const { gamePrize, numberOfBallChoices, animate } = props;
    const { currency, playerCurrency, currencies } = useContext(DataContext);

    return (
        <StyleContainer
            visible={Boolean(numberOfBallChoices.length)}
            animate={animate}
        >
            {currencies.current[currency.current]?.draw(
                gamePrize,
                playerCurrency.current,
            )}
        </StyleContainer>
    );
}

const mapState = (state) => {
    return {
        gamePrize: state.dispenser.gamePrize,
        numberOfBallChoices: state.dispenser.numberOfBallChoices,
    };
};

export default connect(mapState)(PrizeCard);
