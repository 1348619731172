import StyleContainer from './style';
import { BgPyramid, BgScifi, BgForest } from '../../assets/images';
import { Aztec, Forest, Scifi } from '../../assets/videos';

function Background() {
  const params = new URLSearchParams(window.location.search.slice(1));
  const theme = params.get('theme');

  const getPoster = () => {
    switch(theme){
      case 'AZTC':
        return BgPyramid;
      case 'SCFI':
        return BgScifi;
      default:
        return BgForest;
    }
  };
  
  const getVideo = () => {
    switch(theme){
      case 'AZTC':
        return Aztec;
      case 'SCFI':
        return Scifi;
      default:
        return Forest;
    }
  };

  return(
    <StyleContainer className='full-height'>
      <video className='bg-video' poster={getPoster()} autoPlay loop muted>
        <source src={getVideo()} type='video/mp4' />
      </video>
    </StyleContainer>
  );
}

export default Background;
