import { StyleContainer, SwitchContainer } from './style';
import { connect } from 'react-redux';
import { useContext } from 'react';
import { IconContext } from 'react-icons';
import { HiMenu } from 'react-icons/hi';
import { Button, Popover, Switch } from 'antd';
import {
    SndPyramid,
    SndForest,
    SndScifi,
    SndScifiAmbient,
    SndMayanAmbient,
    SndEnchantedForest,
} from '../../assets/audios';
import ReactHowler from 'react-howler';
import { toggleInstructions, toggleMusic, toggleSound } from '../../actions';
import { DataContext } from '../../contexts/DataContextContainer';
import { useTranslation } from 'react-i18next';

function Header(props) {
    const {
        playMusic,
        playSound,
        vol,
        gameSessionId,
        hasTestDeck,
        toggleMusic,
        toggleSound,
        toggleInstructions,
        isInstructions,
    } = props;
    const { currency, playerCurrency, currencies, balance, bet } =
        useContext(DataContext);
    const params = new URLSearchParams(window.location.search.slice(1));
    const theme = params.get('theme');
    const { t } = useTranslation();

    const getMusic = () => {
        switch (theme) {
            case 'AZTC':
                return (
                    <ReactHowler
                        src={SndPyramid}
                        playing={playMusic}
                        loop={true}
                        volume={0.25 * vol}
                    />
                );
            case 'SCFI':
                return (
                    <ReactHowler
                        src={SndScifi}
                        playing={playMusic}
                        loop={true}
                        volume={0.25 * vol}
                    />
                );
            default:
                return (
                    <ReactHowler
                        src={SndForest}
                        playing={playMusic}
                        loop={true}
                        volume={0.25 * vol}
                    />
                );
        }
    };

    const getAmbientSound = () => {
        switch (theme) {
            case 'AZTC':
                return (
                    <ReactHowler
                        src={SndMayanAmbient}
                        playing={playMusic}
                        loop={true}
                        volume={0.25 * vol}
                    />
                );
            case 'SCFI':
                return (
                    <ReactHowler
                        src={SndScifiAmbient}
                        playing={playMusic}
                        loop={true}
                        volume={0.125 * vol}
                    />
                );
            default:
                return (
                    <ReactHowler
                        src={SndEnchantedForest}
                        playing={playMusic}
                        loop={true}
                        volume={0.25 * vol}
                    />
                );
        }
    };

    const parseCurrency = (n) => {
        if (!currencies.current[currency.current]) return 0;
        return currencies.current[currency.current].draw(
            n,
            playerCurrency.current,
        );
    };

    const content = (
        <div className="game-options">
            <SwitchContainer>
                <div>{t('music')}</div>
                <Switch
                    checked={playMusic}
                    size="small"
                    onChange={toggleMusic}
                />
            </SwitchContainer>
            <SwitchContainer>
                <div>{t('sound')}</div>
                <Switch
                    checked={playSound}
                    size="small"
                    onChange={toggleSound}
                />
            </SwitchContainer>
            <SwitchContainer>
                <div>{t('instructions')}</div>
                <Switch
                    checked={isInstructions}
                    size="small"
                    onChange={toggleInstructions}
                />
            </SwitchContainer>
        </div>
    );

    return (
        <StyleContainer>
            {gameSessionId && (
                <div className="game-id">
                    {t('game')} # {gameSessionId}
                    {hasTestDeck ? ' (TEST DECK)' : ''}
                </div>
            )}
            <div className="content">
                <div className="hide">
                    {getMusic()}
                    {getAmbientSound()}
                </div>
                <Popover
                    placement="bottomLeft"
                    content={content}
                    trigger="click"
                    mouseLeaveDelay={3}
                >
                    <Button type="link">
                        <IconContext.Provider value={{ className: 'burger' }}>
                            <HiMenu />
                        </IconContext.Provider>
                    </Button>
                </Popover>
                <div className="balance bg-black">
                    <div>{t('balance')}</div>
                    <div>{parseCurrency(balance.current - bet)}</div>
                </div>
                <div className="bet bg-black">
                    <div>{t('bet')}</div>
                    <div>{parseCurrency(bet)}</div>
                </div>
            </div>
        </StyleContainer>
    );
}

const mapDispatch = (dispatch) => {
    return {
        toggleMusic: () => dispatch(toggleMusic()),
        toggleSound: () => dispatch(toggleSound()),
        toggleInstructions: () => dispatch(toggleInstructions()),
    };
};

const mapState = (state) => {
    return {
        playMusic: state.menu.playMusic,
        playSound: state.menu.playSound,
        isInstructions: state.menu.isInstructions,
        vol: state.menu.vol,
        gameSessionId: state.cards.gameSessionId,
        hasTestDeck: state.cards.hasTestDeck,
    };
};

export default connect(mapState, mapDispatch)(Header);
