import styled from '@emotion/styled';
import { WinCounter, BgGrad } from '../../assets/images';

const StyleContainer = styled.div`
  position: fixed;
  width: 100vw;
  left: 0;
  top: 0;
  overflow: hidden;

  .bg {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .full {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  .badge {
    position: absolute;
    width: 501px;
    height: 505px;
    left: calc((100% - 501px) / 2);
    top: calc((80% - 505px) / 2);

    .badge__intro,
    .badge__idle,
    .badge__idle-out,
    .badge__outro {
      width: 501px;
      height: 415px;
      position: absolute;
    }

    .badge__intro {
      animation: anim-hide 1s 0.1s;
    }

    .badge__idle {
      animation: anim-show 0s 1s forwards;
    }

    .badge__idle-out {
      animation: anim-hide 0s 0.1s forwards;
    }

    .badge__outro {
      animation: anim-hide 0s 1.25s forwards;
    }

    .badge__counter {
      position: absolute;
      width: 376px;
      height: 126px;
      left: calc((100% - 376px) / 2);
      top: 379px;
      padding: 25px;
      background: url(${WinCounter}) center / contain no-repeat;
      transform-origin: center top;
      transform: scale(0);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .hide {
    visibility: hidden;
  }

  @keyframes anim-hide {
    0% { visibility: visible }
    100% { visibility: hidden }
  }

  @keyframes anim-show {
    0% { visibility: hidden }
    100% { visibility: visible }
  }

  @keyframes pop-in {
    0% { transform: scale(0); }
    70% { transform: scale(1.25); }
    100% { transform: scale(1); }
  }

  @keyframes fade-in {
    0% { opacity: 0); }
    100% { opacity: 1; }
  }

  .fade-in {
    animation: fade-in 0.5s forwards;
  }

  .fade-out {
    animation: fade-in 0.5s reverse forwards;
  }

  .badge__text {
    width: 100%;
    height: 220px;
    top: 160px;
    position: absolute;
    font-family: 'PoetsenOne', sans-serif;
    font-size: 60px;
    color: #fff;
    line-height: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform: scale(0);

    p {
      font-size: 75px;
      margin: 0;
    }
  }

  .note-box {
    width: 320px;
    height: 154px;
    position: absolute;
    left: calc((100% - 320px) / 2);
    top: calc((80% - 154px) / 2);
    border: 20px solid transparent;
    border-image-source:url(${BgGrad});  
    border-image-repeat: stretch;
    border-image-slice: 20 fill;
    pointer-events: none;
    text-shadow: 0px 3px 0 rgba(1, 44, 98, 0.5);
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: pop-in 0.5s ease-in forwards;
  }

  .tie {
    text-shadow: 0 -2px 0 #333333, 4px -2px 0 #333333, -4px -2px 0 #333333, 0 6px 0 #333333, 4px 6px 0 #333333, -4px 6px 0 #333333;
  }

  .nice {
    text-shadow: 0 -2px 0 #004d00, 4px -2px 0 #004d00, -4px -2px 0 #004d00, 0 6px 0 #004d00, 4px 6px 0 #004d00, -4px 6px 0 #004d00;
  }

  .big {
    text-shadow: 0 -2px 0 #870901, 4px -2px 0 #870901, -4px -2px 0 #870901, 0 6px 0 #870901, 4px 6px 0 #870901, -4px 6px 0 #870901;
  }

  .huge {
    text-shadow: 0 -2px 0 #104885, 4px -2px 0 #104885, -4px -2px 0 #104885, 0 6px 0 #104885, 4px 6px 0 #104885, -4px 6px 0 #104885;
  }

  .legendary {
    text-shadow: 0 -2px 0 #934a09, 4px -2px 0 #934a09, -4px -2px 0 #934a09, 0 6px 0 #934a09, 4px 6px 0 #934a09, -4px 6px 0 #934a09;
  }

  @media only screen and (max-width: 720px) {
    .badge {
      transform: scale(0.75);
    }
  }
`;

export default StyleContainer;