import styled from '@emotion/styled';
import { Warning } from '../../assets/images';

const StyleContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  text-align: center;
  color: #fff;
  z-index: 5;

  .btn {
    cursor: pointer;
  }

  .btn:active {
    transform: scale(0.95);
  }

  .modal {
    width: 460px;
    height: 457px;
    background-color: #196930;
    border-radius: 22px;
    position: absolute;
    padding: 20px;
  }

  .warning {
    position: relative;
    width: 92px;
    height: 84px;
    margin: 27px auto 36px;
    background: url(${Warning}) center / contain no-repeat;
  }

  h1 {
    margin-bottom: 0.7em;
    color: #fff;
    font-size: 35px;
    text-shadow: 1px 1px 0 rgba(0, 74, 29, 0.6);
  }

  p {
    font-size: 24px;
    text-shadow: 1px 1px 0 rgba(0, 74, 29, 0.6);
    margin-bottom: 0.3em;
  }

  .btn--red {
    position: absolute;
    bottom: 35px;
    left: calc((100% - 300px) / 2);
    width: 300px;
    height: 75px;
    border-radius: 37px;
    background: linear-gradient(to top, #ca2525, #fd6b6b);
    box-shadow: 0px 11px 22px 0 rgba(0, 0, 0, 0.3);
    font-weight: 700;
    font-size: 32px;
    text-shadow: 1px 1px 0 rgba(102, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: 720px) {
    .modal {
      width: 340px;
      height: 380px;
    }

    .warning {
      width: 69px;
      height: 63px;
      margin: 10px auto 26px;
    }

    h1 {
      font-size: 30px;
    }

    p {
      font-size: 20px;
    }

    .btn--red {
      left: calc((100% - 234px) / 2);
      width: 234px;
      height: 58px;
      font-size: 25px;
    }
  }
`;

export default StyleContainer;