import { RESET, UPDATE_CARDS, SET_PLAYER, GAME_COMPLETE, GAME_OVER, DRAW_BALL, OPPONENT_TURN, REVEAL_BALL, WARNING, UPDATE_TURN, SET_ALL } from '../actions';

const initialState = {
  winningNum: 0,
  winnerId: '',
  currentTurn: 0,
  gameSessionId: '',
  hasTestDeck: false,
  picked: [],
  players: [],
  tieBreaker: [],
  warningMsg: '',
  cancel: false,
  joinWaitSeconds: 0,
  joinWaitRemaining: 0
};

const playerProps = {playerAlias:'', predictiveTiles:[], winNum:[], card:[], playerTurn: 0, playerSessionId: '', playTie: false};

function getCard(card){
  return card.map((value, index) => {
    return {num: index === 12 ? '' : value, drawn: index === 12, latest: false, flash: false};
  });
}

function updatePlayer(player, daubs, predictiveTiles, lastNum){
  const newPlayer = Object.assign({}, player);
  newPlayer.card = player.card.map( square => Object.assign({}, square, {drawn: false, latest: false, flash: false}) );
  daubs.forEach(index => {
    newPlayer.card[index].drawn = true;
    if(lastNum && newPlayer.card[index].num === lastNum) newPlayer.card[index].latest = true;
  });
  newPlayer.predictiveTiles = predictiveTiles.slice();
  newPlayer.predictiveTiles.forEach(num => {
    const index = newPlayer.card.findIndex(value => value.num === num);
    newPlayer.card[index].flash = true;
    checkWinLines(newPlayer, index);
  });
  return newPlayer;
}

function checkMoves(player, a){
  const set = new Set(player.winNum);
  if(a.length === 5){
    a.forEach(value => set.add(value.num));
    player.winNum = Array.from(set);
  }else if(a.length === 4){
    a.forEach(value => value.flash = true);
  }
}

function checkWinLines(player, index){
  let i=0, j=0, n=0, a=[];

  // check column
  n = Math.floor(index / 5);
  for(i = 0; i < 5; i++){
    j = n * 5 + i;
    if(player.card[j].drawn) a.push(player.card[j]);
  }
  checkMoves(player, a);

  // check row 
  n = index % 5;
  a = [];
  for(i = 0; i < 5; i++){
    j = i * 5 + n;
    if(player.card[j].drawn) a.push(player.card[j]);
  }
  checkMoves(player, a);

  // check diagonal
  if(index % 6 === 0){
    a = [];
    for(i=0; i<5; i++){
      j = i * 6;
      if(player.card[j].drawn) a.push(player.card[j]);
    }
    checkMoves(player, a);
  }else if(index % 4 === 0 && index > 0 && index < 24){
    a = [];
    for(i = 0; i < 5; i++){
      j = (i + 1) * 4;
      if(player.card[j].drawn) a.push(player.card[j]);
    }
    checkMoves(player, a);
  }
}

function getPicked(players, balls){
  balls.reverse().forEach(ball => {
    const i = players.findIndex(player => {
      return player.picks.some(pick => pick === ball);
    });
    if(i >= 0) players.unshift(players.splice(i, 1)[0]);
  });
  return players;
}

function getPlayer(opponents, players){
  return opponents.find( m => players.every( n => n.playerAlias !== m.playerAlias ) );
}

export default function cards(state = initialState, action){
  let players = [], player;
  switch(action.type){
    case RESET:
      return Object.assign({}, initialState);
    case SET_PLAYER:
      players = state.players.slice();
      while(players.length < 3 - action.payload.vacantPlayerSlots){
        if(players.length === 0){
          player = action.payload.self;
        }else{
          player = getPlayer(action.payload.opponents, players);
        }
        const playerSessionId = players.length === 0 ? action.payload.playerSessionId : action.payload.whoJoined && action.payload.playerSessionId !== action.payload.whoJoined ? action.payload.whoJoined : '';
        players.push( Object.assign({}, playerProps, { ...player, playerSessionId, card: getCard(player.card) }) );
      }
      return Object.assign({}, state, { players, gameSessionId: action.payload.gameSessionId, hasTestDeck: action.payload.hasTestDeck, joinWaitSeconds: state.joinWaitSeconds || action.payload.joinWaitSeconds || action.payload.timings?.joinWaitSeconds, joinWaitRemaining: action.payload.joinWaitRemaining || action.payload.timings?.joinWaitRemaining });
    case SET_ALL:
      [action.payload.self].concat(action.payload.opponents).forEach(p => {
        player = Object.assign({}, playerProps, { ...p, card: getCard(p.card) });
        players.push(updatePlayer(player, p.daubs, p.predictiveTiles));
      });
      return Object.assign({}, state, { players });
    case UPDATE_TURN:
      return Object.assign({}, state, { currentTurn: state.players.find(p => p.playerAlias === action.tieBreaker[state.tieBreaker.length]).playerTurn, tieBreaker: action.tieBreaker.slice(0, state.tieBreaker.length + 1) });
    case DRAW_BALL:
      return Object.assign({}, state, { currentTurn: state.players.find(p => p.playerAlias === action.playerAlias)?.playerTurn || 0, tieBreaker: action.tie ? state.tieBreaker.slice().concat(action.playerAlias) : state.tieBreaker.slice() });
    case OPPONENT_TURN:
      players = state.players.slice().map(player => {
        if(player.playerAlias === action.opponent.playerAlias){
          player.playerTurn = action.opponent.currentTurn;
        }else{
          player.playerTurn = -1;
        }
        return player;
      });
      return Object.assign({}, state, { players, currentTurn: 0 });
    case REVEAL_BALL:
      players = state.players.slice().map(player => {
        const p = action.opponents.concat(action.self || []).find(value => value.playerAlias === player.playerAlias);
        if(!player.playTie) player.playTie = p?.playTie;
        if(!player.playerSessionId && player.playerTurn === state.currentTurn) player.playerSessionId = action.whoPicked;
        player.card.forEach(square => square.latest = false);
        return player;
      });
      return Object.assign({}, state, { players });
    case UPDATE_CARDS:
      const winningNum = action.ballCalls.slice(-1)[0];
      players = state.players.slice().map( p => {
        player = action.opponents.concat(action.self).find(value => value.playerAlias === p.playerAlias);
        return updatePlayer(p, player.daubs, player.predictiveTiles, winningNum);
      });
      return Object.assign({}, state, { players, winningNum });
    case GAME_COMPLETE:
      return Object.assign({}, state, { winnerId: action.winnerId });
    case GAME_OVER:
      return Object.assign({}, state, { winnerId: action.payload.whoWon, picked: getPicked(action.payload.opponents.concat(action.payload.self), action.payload.ballCalls.slice(0, 3)) });
    case WARNING: 
      return Object.assign({}, state, { warningMsg: action.msg, cancel: action.cancel });
    default:
      return state;
  }
}

