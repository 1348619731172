import { TOGGLE_MUSIC, TOGGLE_SOUND, CHANGE_VOLUME, TOGGLE_INSTRUCTIONS } from '../actions';

const initialState = {
  isInstructions: true,
  playMusic: true,
  playSound: true,
  vol: 1,
};

export default function menu(state = initialState, action){
  switch(action.type){
    case TOGGLE_MUSIC:
      return Object.assign({}, state, {playMusic: !state.playMusic});
    case TOGGLE_SOUND:
      return Object.assign({}, state, {playSound: !state.playSound});
    case TOGGLE_INSTRUCTIONS:
      return Object.assign({}, state, {isInstructions: !state.isInstructions});
    case CHANGE_VOLUME:
      return Object.assign({}, state, {vol: action.vol});
    default:
      return state;
  }
}