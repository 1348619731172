import styled from '@emotion/styled';

export const StyleContainer = styled.div`
    width: 100%;
    height: 48px;
    margin-top: 6px;
    z-index: 1;
    position: relative;

    .game-id {
        font-size: 12px;
        font-weight: 500;
        color: #fff;
        text-align: right;
    }

    .content {
        width: 100%;
        height: 34px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .ant-btn {
        padding: 0;
    }

    .hide {
        display: none;
    }

    .burger {
        width: 30px;
        height: 30px;
        cursor: pointer;
        color: #fff;
        opacity: 0.75;
        transition: all 0.25s;

        :active {
            opacity: 0.25;
        }
    }

    .bg-black {
        height: 34px;
        border-radius: 17px;
        background-color: rgba(0, 0, 0, 0.75);
        font-size: 18px;
        font-weight: 700;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        > div:first-child {
            margin: 0 5px 0 10px;
        }

        > div:nth-child(2) {
            color: #fff;
            width: 60%;
            margin-right: 5px;
            text-align: center;
        }
    }

    .balance {
        width: 234px;

        > div:first-child {
            color: #f33;
        }
    }

    .bet {
        width: 185px;
        margin-left: 10px;

        > div:first-child {
            color: #fc0;
        }
    }

    @media only screen and (max-width: 720px) {
        .content {
            padding: 0 8px;
        }
        .bg-black {
            height: 30px;
            font-size: 16px;
        }

        .balance {
            width: 190px;
        }
    }
`;

export const SwitchContainer = styled.div`
    width: 130px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
