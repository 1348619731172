export default class Currency {
    constructor(currency, data) {

        this.id = currency;
        this.centSymbol = data.centsSym;
        this.centSymbolOnRight = (data.centsPos === "R");
        this.centPrecision = data.decimalPrec;
        this.symbol = data.symbol;
        this.symbolOnLeft = (data.pos === "L");
        this.centSeparator = data.sep;
        this.thousandsSeparator = data.thousandsSep;
        this.conversions = data.convert;
    }

    draw(value, valueCurrency) {
        let drawnCurrency = "";

        if(valueCurrency !== undefined &&
            this.conversions[valueCurrency] !== undefined &&
            this.id !== this.conversions[valueCurrency])
        {
            value *= this.conversions[valueCurrency];
        }

        if(this.symbolOnLeft) {
            drawnCurrency += this.symbol;
        }

        //value = value / Math.pow(10, this.get('decimalPrec'));
        drawnCurrency += parseFloat(value).formatMoney(this.centPrecision, this.centSeparator, this.thousandsSeparator);

        if(!this.symbolOnLeft) {
            drawnCurrency += this.symbol;
        }

        return drawnCurrency;
    }
}

// Attach helper method for format currency
Number.prototype.formatMoney = function(c, d, t){
    var n = this,
        s = n < 0 ? "-" : "",
        i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "",
        j = (j = i.length) > 3 ? j % 3 : 0;

    c = isNaN(c = Math.abs(c)) ? 2 : c;
    d = d === undefined ? "." : d;
    t = t === undefined ? "," : t;

    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};