import styled from '@emotion/styled';

const StyleContainer = styled.div`
  width: 100%;
  height: 479px;
  margin-bottom: 50px;
  position: relative;

  @media only screen and (max-height: 830px) {
    transform: scale(0.9);
  }

  @media only screen and (max-height: 750px) {
    transform: scale(0.8);
  }

  @media only screen and (max-height: 670px) {
    transform: scale(0.7);
    margin-bottom: 300px;
  }
`;

export default StyleContainer;