import styled from '@emotion/styled';

const StyleContainer = styled.div`
  position: absolute;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &.card0 {
    left: 96px;
    top: 6px;
  }

  &.card1 {
    left: 26px;
    top: 211px;
  }

  &.card2 {
    left: 186px;
    top: 211px;
  }

  .column {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    .text {
      font-weight: 700;
      font-size: 14px;
      color: #fff;
      text-align: center;
    }
  }

  .squares {
    background-color: rgba(1, 44, 98, 0.5);
    border-radius: 10px;
    box-shadow: 0px 6px 8px rgba(51, 59, 69, 0.37);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
  }

  &.card0 > .column,
  &.card0 > .squares {
    width: 168px;
    height: 168px;
  }

  &.card1 > .column,
  &.card2 > .column,
  &.card1 > .squares,
  &.card2 > .squares {
    width: 148px;
    height: 148px;
  }
`;

export default StyleContainer;