import StyleContainer from './style';
import { connect, useStore } from 'react-redux';
import Ball from '../Ball';
import MessageBar from '../MessageBar';
import { gsap } from 'gsap';
import { useEffect, useRef, useState } from 'react';

function Dispenser(props) {
  const { numberOfBallChoices } = props;
  const store = useStore();
  const disRef = useRef();
  const tieBreaker = store.getState().cards.tieBreaker;
  const [shown, setShown] = useState(false);

  const cards = [];
  for(let i = 0; i < 3; i++){
    cards.push(store.getState().cards['card' + i]);
  }

  useEffect(() => {
    if(numberOfBallChoices.length && !shown){
      gsap.to(disRef.current, { duration: 0.5, y: 0, onComplete: () => { setShown(true); } });
    }
  }, [numberOfBallChoices, shown]);


  const BallRow = () => {
    const a = [];
    for(let i = 0; i < 15; i++){
      a.push(<Ball key={i} id={i} shown={shown} activeBall={i % 4 === 3 && Math.floor(i / 4) < numberOfBallChoices[0]} />);
    }
    return a;
  }

  if(numberOfBallChoices.length){
    return(
      <StyleContainer style={{ overflow: !shown ? 'hidden' : 'visible', zIndex: tieBreaker.length ? 4 : 0 }}>
        <div className='dispenser' ref={disRef}>
          <div className='container'>
            <div className='ball-highway'>
              <BallRow />
            </div>
          </div>
        </div>
        <MessageBar />
      </StyleContainer>
    );
  }else{
    return(
      <StyleContainer></StyleContainer>
    );
  }
}

const mapState = state => {
	return {
		numberOfBallChoices: state.dispenser.numberOfBallChoices
	};
}

export default connect(mapState)(Dispenser);
