import styled from '@emotion/styled';

const StyleContainer = styled.div`
  background-color: rgba(1, 44, 98, 0.5);
  margin: 3px 0 0 0;
  border-radius: 10px;
  width: 100%;
  height: ${props => props.id ? 26 : 30}px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  color: ${props => props.active ? '#f4e20f' : '#fff'};
  font-weight: 700;
  font-size: ${props => props.id ? 15 : 17}px;
  text-shadow: rgba(0, 0, 0, 0.25) 0px 1px 2px;

  .dots {
    width: 20px;
  }

  .dots::before {
    content: '';
    animation: dots 2s linear infinite;
  }

  @keyframes dots {
    0% { content: ''; }
    25% { content: '.'; }
    50% { content: '..'; }
    75% { content: '...'; }
  }
`;

export default StyleContainer;