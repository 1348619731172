import StyleContainer from './style';
import Card from '../Card';
import PlayerStatus from '../PlayerStatus';

function Players() {
  return(
    <StyleContainer>
      <Card id={0} />
      <Card id={1} />
      <Card id={2} />
      <PlayerStatus />
    </StyleContainer>
  );
}

export default Players;
