import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import BuyIn from './components/BuyIn';
import WaitScreen from './components/WaitScreen';
import Game from './components/Game';
import Summary from './components/Summary';
import Header from './components/Header';
import Warning from './components/Warning';
import Background from './components/Background';
import DataContextContainer from './contexts/DataContextContainer';
import './App.css';

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <DataContextContainer>
          <Background />
          <div className='wrapper full-height'>
            <Header />
            <Routes>
                <Route path='/lobby' element={<BuyIn />} />
                <Route path='/wait' element={<WaitScreen />} />
                <Route path='/game' element={<Game />} />
                <Route path='/summary' element={<Summary />} />
                <Route path="/" element={<Navigate replace to="/lobby" />} />
            </Routes>
          </div>
          <Warning />
          <div className='rotate full-height'></div>
        </DataContextContainer>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
