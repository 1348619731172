import StyleContainer from './style';
import { connect, useStore } from 'react-redux';
import { changeVolume } from '../../actions';
import { useCallback, useEffect, useRef, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { gsap } from 'gsap';
import ApngComponent from 'react-apng';
import { Star, WinRays, WinGoldIdle, WinGoldIntro, WinGoldOutro, WinBlueIdle, WinBlueIntro, WinBlueOutro, WinGreenIdle, WinGreenIntro, WinGreenOutro, WinRedIdle, WinRedIntro, WinRedOutro, WinTieIdle, WinTieIntro, WinTieOutro } from '../../assets/images';
import TextResizer from '../TextResizer';
import { SndWin } from '../../assets/audios';
import { Trans, useTranslation } from 'react-i18next';
import { DataContext } from '../../contexts/DataContextContainer';

function Badge(props) {
  const { tieBreaker, winnerId, gamePrize, changeVolume } = props;
  const store = useStore();
  const navigate = useNavigate();
  const player = store.getState().cards.players[0];
  const isWinner = player?.playerSessionId === winnerId;
  const tie = tieBreaker.length > 0 && player?.playTie;
  const prize = Number(gamePrize);
  const [show, setShow] = useState(false);
  const [showWin, setShowWin] = useState(false);
  const isShown = useRef(false);
  const intro = useRef();
  const idle = useRef();
  const outro = useRef();
  const text = useRef();
  const stars = useRef([]);
  const canvasRef = useRef(null);
  const counter = useRef();
  const { currency, playerCurrency, currencies } = useContext(DataContext);
  const { t } = useTranslation();

  const getText = useCallback((b) => {
    if(!b)
      return 'tie';
    else if(prize < 4)
      return 'nice';
    else if(prize < 25)
      return 'big';
    else if(prize < 120)
      return 'huge';
    else
      return 'legendary';
  }, [prize]);

  const getIntro = (b) => {
    if(!b)
      return WinTieIntro;
    else if(prize < 4)
      return WinGreenIntro;
    else if(prize < 25)
      return WinRedIntro;
    else if(prize < 120)
      return WinBlueIntro;
    else
      return WinGoldIntro;
  };

  const getIdle = (b) => {
    if(!b)
      return WinTieIdle;
    else if(prize < 4)
      return WinGreenIdle;
    else if(prize < 25)
      return WinRedIdle;
    else if(prize < 120)
      return WinBlueIdle;
    else
      return WinGoldIdle;
  };

  const getOutro = (b) => {
    if(!b)
      return WinTieOutro;
    else if(prize < 4)
      return WinGreenOutro;
    else if(prize < 25)
      return WinRedOutro;
    else if(prize < 120)
      return WinBlueOutro;
    else
      return WinGoldOutro;
  };
  
  const preloadImages = () => {
    //preload images
    let img0 = new Image();
    img0.src = getIntro(Boolean(prize));
    let img1 = new Image();
    img1.src = getIdle(Boolean(prize));
    let img2 = new Image();
    img2.src = getOutro(Boolean(prize));
  };

  useEffect(() => {
    if(!canvasRef.current) return null;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const wRay = 1140;
    const hRay = 1143;
    let req;
    let deg = 0;

    const img = new Image();
    img.src = Star;

    const ray = new Image();
    ray.src = WinRays;

    const reset = () => {
      return { x: canvas.width / 2, y: canvas.height/2, speedX: 3 - Math.random() * 6, speedY: 3 - Math.random() * 6, scale: Math.random() * 0.5, width: 119, height: 119, alpha: Math.random() * 2 };
    }

    while(stars.current.length < 150){
      stars.current.push(reset());
    }

    const draw = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.globalAlpha = 1;
      for(let i = 0; i < stars.current.length; i++){
        if(stars.current[i].alpha <= 0){
          stars.current[i] = reset();
        }
        stars.current[i].x += stars.current[i].speedX;
        stars.current[i].y += stars.current[i].speedY;
        stars.current[i].alpha = Math.max(0, stars.current[i].alpha - 0.01);
        const width = stars.current[i].width * stars.current[i].scale;
        const height = stars.current[i].height * stars.current[i].scale;
        ctx.save();
        ctx.globalAlpha = stars.current[i].alpha;
        ctx.drawImage(img, 0, 0, stars.current[i].width, stars.current[i].height, stars.current[i].x - width / 2, stars.current[i].y - height / 2, width, height);
        ctx.restore();
      }
      ctx.save();
      ctx.translate(canvas.width/2, canvas.height/2);
      ctx.rotate(deg * Math.PI / 180);
      ctx.drawImage(ray, 0, 0, wRay, hRay, -wRay/4, -hRay/4, wRay/2, hRay/2);
      ctx.restore();
      deg = (deg + 1) % 360;
      req = requestAnimationFrame(draw);
    }

    requestAnimationFrame(draw);

    return () => cancelAnimationFrame(req);
  });

  useEffect(() => {
    let timer = null;

    const loopFinish = () => {
      isShown.current = false;
      const query = new URLSearchParams(window.location.search.slice(1));
      let params = `/summary?${query.toString()}`;
      navigate(params, {replace: true});
    }
    
    if(isWinner && !tie && !showWin){
      setShowWin(true);
    }else if(tie && show && winnerId && !showWin){
      setShow(false);
    }else if(isWinner || tie){
      if(!isShown.current){
        if(!show){
          setShow(true);
        }else{
          isShown.current = true;
          setTimeout(() => {
            intro.current.one();
            idle.current.play();
            if(isWinner && store.getState().menu.playMusic){
              changeVolume(0.5);
              const playSndWin = new Audio(SndWin);
              playSndWin.play();
            }
          }, 250);
          if(isWinner){
            timer = setTimeout(() => setShow(false), 5000);
          }
        }
      }else{
        if(!show){
          setTimeout(() => {
            idle.current.stop();
            outro.current.one();
          }, 100);
          timer = setTimeout(() => {
            if(!showWin && isWinner){
              isShown.current = false;
              setShowWin(true);
            }else{
              loopFinish();
            }
          }, 2500);
        }
      }
    }else if(winnerId){
      setTimeout(() => loopFinish(), 1500);
    }

    return () => clearTimeout(timer);
  }, [isWinner, tie, showWin, show, winnerId, navigate, store, changeVolume]);

  useEffect(() => {
    if(counter.current){
      gsap.to(counter.current, {scale: show ? 1 : 0, duration: 0.5, delay: show ? 0 : 0.75, ease: show ? 'back.out(1.7)' : 'back.in(1.7)'});
      gsap.to(text.current, {scale: show ? 1 : 0, duration: 0.5, delay: show? 0.5 : 0.75, ease: show ? 'back.out(1.7)' : 'back.in(1.7)'});
    }
  }, [show]);

  const style = {
    width: '100%',
    fontWeight: '700',
    color: '#ffd800',
    textAlign: 'center',
    textShadow: '1px -1px 1px #c9800c, 0 2px 0 #b36a06, 0 2px 4px #000',
  }

  preloadImages();

  if(tie || isWinner){
    return(
      <StyleContainer className='full-height' style={{ zIndex: isWinner ? 4 : 3 }}>
        <div className={'full bg ' + (show ? 'fade-in' : 'fade-out')}>
          <canvas ref={canvasRef} width={window.innerWidth} height={window.innerHeight} />
        </div>
        <div className='badge'>
          {show && <ApngComponent ref={intro} className='hide badge__intro' src={getIntro(showWin)} rate={1} />}
          <ApngComponent ref={idle} className={show ? 'hide badge__idle' : 'badge__idle-out'} src={getIdle(showWin)} rate={1} />
          {!show && <ApngComponent ref={outro} className='badge__outro' src={getOutro(showWin)} rate={1} />}
          <div ref={text} className={'badge__text ' + getText(showWin)}><Trans>{getText(showWin)}</Trans></div>
          <div ref={counter} className='badge__counter'>
            <TextResizer fontSize={260} style={style} text={isWinner && showWin ? currencies.current[currency.current].draw(gamePrize, playerCurrency.current) : t('hiBallWins')} />
          </div>
        </div>
      </StyleContainer>
    );
  }else if(tieBreaker.length){
    return(
      <StyleContainer className='full-height' style={{ zIndex: 3 }}>
        <div className='full bg show'></div>
        <div className='note-box'>OPPONENTS ARE CURRENTLY IN TIE BREAKER ROUND</div>
      </StyleContainer>
    );
  }else{
    return null;
  }
}

const mapDispatch = dispatch => {
  return {
    changeVolume: (vol) => dispatch(changeVolume(vol))
  };
}

const mapState = state => {
  return {
		tieBreaker: state.cards.tieBreaker,
    winnerId: state.cards.winnerId,
    gamePrize: state.dispenser.gamePrize
  };
}

export default connect(mapState, mapDispatch)(Badge);
