import StyleContainer from './style';
import { connect, useStore } from 'react-redux';
import Square from '../Square';
import { Spinner } from 'react-activity';
import 'react-activity/dist/Spinner.css';
import { useEffect } from 'react';
import Label from '../Label';
import { useRef } from 'react';
import { gsap } from 'gsap';
import { SndMagicalExplosion } from '../../assets/audios';
import { useTranslation } from 'react-i18next';

function Card(props) {
  const { id, players } = props;
  const store = useStore();
  const ballsPicked = store.getState().dispenser.ballsPicked;
  const currentTurn = store.getState().cards.currentTurn;
  const player = players[id];
  const hasLatest = player?.card && player.card.some(square => square.latest);
  const cardRef = useRef();
  const { t } = useTranslation();

  const playMagicalExplosion = new Audio(SndMagicalExplosion);

  const getWinners = () => {
    let n = !currentTurn ? 10 : 5;
    let a=[];
    players.forEach(player => {
      if(player.winNum.length === n){
        a.push(player.playerTurn);
      }
    });
    a.sort((a,b) => a-b);
    return a;
  }

  const winners = players.length ? getWinners() : [];

  useEffect(() => {
    if(player?.card.length && hasLatest){
      gsap.set(cardRef.current, {x: 10, y: 5 - Math.random() * 10, delay: 0.5});
      gsap.to(cardRef.current, {x: 0, y: 0, delay: 0.5, duration: 0.5, ease: 'elastic.out', 
        onStart: () => {
          if(!id && store.getState().menu.playSound) playMagicalExplosion.play();
        }
      });
    }
  });

  const getZ = () => {
    return winners.some(playerTurn => playerTurn === player.playerTurn) ? 3 : hasLatest ? 2 - id : 0;
  }

  if(player?.card){
    return(
      <StyleContainer ref={cardRef} className={'card' + id} style={{ zIndex: getZ() }}>
        <div className='squares'>
          {player.card.map((item, index) => {
            let winner = false;
            if((currentTurn && player.winNum.length === 5) || (!currentTurn && player.winNum.length === 10)){
							winner = player.winNum.some(num => num === item.num);
						}
            return <Square key={index} id={index} cardId={id} num={item.num} drawn={item.drawn} latest={item.latest} flash={item.flash} winner={winner} complete={false} ballPicked={ballsPicked.slice(-1)[0]} isPlayer={players[0].playerTurn === currentTurn || !currentTurn} />
          })}
        </div>
        <Label id={id} name={players[id].playerAlias} />
      </StyleContainer>
    );
  }else{
    return(
			<StyleContainer className={'card' + id}>
        <div className='column'>
					<div className='text'>{t('waitPlayer', { n: id + 1 })}</div>
          <Spinner color='gold' size={24} speed={1} animating={true} />
        </div>
			</StyleContainer>
		);
  }
}

const mapState = state => {
	return {
    players: state.cards.players,
	};
}

export default connect(mapState)(Card);
