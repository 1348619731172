import styled from '@emotion/styled';
import { ThumbUp, ThumbDown, Ball } from '../../assets/images';

const StyleContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .ball {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: url(${Ball}) 50% 0 / cover no-repeat;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center; 

    .text {
      font-weight: 900;
      font-size: 11px;
      color: #242e42;
      animation: ${props => props.tie ? 'none' : 'hide 0s 2s forwards'};
    }
  }

  .ball--switch {
    animation: ball-switch 0.6s ${props => props.id * 0.2 + 1.3}s steps(1) infinite;
  }

  @keyframes ball-switch {
    0% { background-position: 50% 100%; }
    33% { background-position: 50% 0%; }
  }

  .ball--reset {
    animation: ball-switch 0s 2s steps(1) reverse forwards;
  }
  
  .ball--player {
    background-position: 50% 100% !important;
  }

  .ball--glow {
    animation: glow 0.1s ${props => props.delay}s forwards;
  }

  @keyframes glow {
    100% { box-shadow: 0 0 16px #7cfff1; }
  }

  .ball--light::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #fff;
    opacity: 1;
    animation: light 0.25s forwards;
  }

  @keyframes light {
    100% { opacity: 0; }
  }

  .thumb {
    position: absolute;
    transform: scale(0);
    animation: scale-in 0.25s 0.25s forwards cubic-bezier(0,1,1,1.5), hide 0s 2s forwards;
  }

  @keyframes scale-in {
    100% { transform: scale(1); }
  }

  @keyframes hide {
    100% { opacity: 0; }
  }

  .thumb--selected {
    width: 18px;
    height: 18px;
    bottom: 5px;
    left: -11px;
  }

  .thumb--unselected {
    width: 10px;
    height: 10px;
    bottom: 2px;
    left: -3px;
  }

  .thumb--down {
    background: url(${ThumbDown}) center / contain no-repeat;
  }

  .thumb--up {
    background: url(${ThumbUp}) center / contain no-repeat;
  }

  .CircularProgressbar {
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    left: -3px;
    top: -3px;
    position: absolute;
  }

  .CircularProgressbar-path {
    stroke: #fff;
    stroke-linecap: butt;
    stroke-dashoffset: -308px;
    animation: circular-anim ${props => props.pickTimeOut}s ${props => props.delay}s linear forwards;
  }

  @keyframes circular-anim {
    0% { stroke-dashoffset: 0; }
    100% { stroke-dashoffset: -308px; }
  }

  .CircularProgressbar-trail {
    stroke: none;
  }
`;

export default StyleContainer;