import styled from '@emotion/styled';
import { BtnGreen, CircleGreen, CircleRed } from '../../assets/images';

const StyleContainer = styled.div`
  width: 100%;
  height: calc(100% - 54px);
  min-height: 740px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  transform-origin: top center;

  .header {
    position: relative;
    width: 300px;
    border-radius: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px 10px 5px;
    margin-top: 20px;
  }

  .winner {
    background-color: #00a001;
    margin-bottom: 10px;
  }

  .loser {
    background-color: #de251d;
  }

  .summary {
    width: 360px;
    background-color: #00a1a7;
    text-align: center;
    text-shadow: rgba(0, 0, 0, 0.5) 0px 3px 0px;
  }

  .card {
    transform: scale(0.75);
    margin: -5px 0;
  }
  
  .squares {
    width: 168px;
    height: 168px;
    background-color: rgba(1, 44, 98, 0.5);
    border-radius: 10px;
    box-shadow: 0px 6px 8px rgba(51, 59, 69, 0.37);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
  }

  .round {
    border-radius: 10px;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .button {
    width: 140px;
    height: 48px;
    background: url(${BtnGreen}) center / cover;
    color: #fff;
    text-align: center;
    text-shadow: rgba(0, 0, 0, 0.3) 0px 2px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  .btn {
    cursor: pointer;
    transition: all 0.25s;

    :active {
      opacity: 0.25;
    }
  }

  .summary-box {
    width: 320px;
    background-image: linear-gradient(rgba(1, 44, 98, 0.5), rgba(1, 44, 98, 0));
    color: #fff;
    text-shadow: rgba(0, 0, 0, 0.25) 0px 1px 2px;
  }

  h3 {
    width: 100%;
    text-align: left;
    color: #fff;
    margin: 0;
    font-weight: 700;
  }

  .balls {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 5px;
  }

  .ball {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    margin: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    color: #fff;
  }

  .ball--win {
    animation: pulse 0.75s infinite;
  }

  @keyframes pulse {
    33% { opacity: 0.5 }
    66% { opacity: 1 }
  }

  .circle-green {
    background: url(${CircleGreen}) center / cover;
  }

  .circle-red {
    background: url(${CircleRed}) center / cover;
  }

  @media only screen and (max-width: 720px) {
    .summary {
      width: 340px;
    }
  }

  @media only screen and (max-height: 865px) {
    transform: scale(0.75);
  }

  @media only screen and (max-height: 667px) {
    transform: scale(0.65);
  }
`;

export default StyleContainer;