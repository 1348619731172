import styled from '@emotion/styled';
import { BgPrize, BgGrad, BgPrizeReveal } from '../../assets/images';

const StyleContainer = styled.div`
    top: 0;
    width: 100vw;
    min-width: 360px;
    position: fixed;
    pointer-events: none;
    overflow: hidden;

    &.fade-out {
        animation: fade-in 0.5s 9s reverse forwards;
    }

    @keyframes drop {
        0% {
            bottom: calc(100% + 266px);
        }
        50% {
            bottom: calc(100% + 266px);
        }
        100% {
            bottom: 0;
        }
    }

    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes fade-white {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes drop-fade {
        100% {
            opacity: 0;
            top: 40%;
        }
    }

    @keyframes up {
        100% {
            top: ${(props) => -Math.min(props.scale * 20, 20)}px;
        }
    }

    @keyframes scale-down {
        100% {
            transform: scale(1);
        }
    }

    h1 {
        font-size: 60px;
        font-weight: bold;
        color: #ffd912;
        text-shadow: 0px 3px 0 rgba(1, 44, 98, 0.5);
        text-align: center;
        margin: 0.3em 0 0.35em;
    }

    p {
        text-shadow: 0px 3px 0 rgba(1, 44, 98, 0.5);
        font-size: 24px;
        font-weight: bold;
        color: #fff;
        margin: 0;
        text-align: center;
    }

    .prize-reveal {
        width: 458px;
        height: 113px;
        margin: 55px auto -16px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background: url(${BgPrizeReveal}) center / contain no-repeat;
        opacity: 0;
        animation:
            fade-in 0.5s 0.5s forwards,
            fade-in 0.5s 9s reverse forwards;

        p {
            margin-bottom: 18px;
        }
    }

    .ready-box {
        width: 420px;
        height: 205px;
        margin: 0 auto;
        position: relative;
        border: 20px solid transparent;
        border-image-source: url(${BgGrad});
        border-image-repeat: stretch;
        border-image-slice: 20 fill;
        opacity: 0;
        animation:
            fade-in 0.5s 0.5s forwards,
            fade-in 0.5s 9s reverse forwards;
    }
    .ready-box.es p {
        margin-top: -10px;
    }
    .ready-box.pt p {
        margin-top: -10px;
    }

    > .container {
        width: 452px;
        height: 800px;
        left: calc((100% - 452px) / 2);
        top: 0;
        transform-origin: center top;
        transform: scale(${(props) => props.scale});
        position: absolute;

        .chest {
            width: 427px;
            height: 266px;
            left: calc((100% - 427px) / 2);
            position: absolute;
            bottom: 0;
        }

        .chest--drop {
            animation: drop 0.5s ease-in;
        }

        .chest--glow {
            animation: fade-in 0.5s 8s reverse forwards;
        }

        .particles {
            width: 452px;
            height: 566px;
            left: calc((100% - 452px) / 2);
            top: 174px;
            position: absolute;
        }

        .hide {
            visibility: hidden;
        }

        .glow {
            left: 0;
            bottom: 120px;
            width: 452px;
            height: 400px;
            position: absolute;
            filter: blur(8px);
        }

        .fade-in {
            opacity: 0;
            animation: fade-in 0.25s 0.75s forwards;
        }

        .particles.fade-in {
            animation: fade-in 0.25s 1s forwards;
        }

        .glow.fade-in {
            animation:
                fade-in 0.25s 1s forwards,
                fade-in 0.5s 8s reverse forwards;
        }

        .prize-box {
            width: 721px;
            height: 212px;
            left: calc((100% - 721px) / 2);
            border-radius: 110px;
            transform: scale(0.25);
            background: url(${BgPrize}) center / contain no-repeat;
            box-shadow: 0 6px 8px rgba(51, 59, 69, 0.37);
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            font-size: 120px;
            font-weight: 700;
            color: #ffd800;
            text-align: center;
            text-shadow:
                1px -1px 1px #c9800c,
                0 2px 0 #b36a06,
                0 2px 4px #000;
        }

        #prize0 {
            top: 294px;
        }

        #prize1 {
            top: 364px;
        }

        #prize2 {
            top: 434px;
        }

        .show {
            opacity: 0;
        }

        .show::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            border-radius: 110px;
            background-color: #fff;
        }

        .show::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            border-radius: 110px;
            border: 12px solid #6dc6f0;
            box-sizing: border-box;
            opacity: 0;
        }

        #prize0.show {
            animation:
                fade-in 0s 5s forwards,
                up 0.5s 8.5s ease-in forwards;
        }

        #prize0.show::after {
            animation: fade-white 1s 5s ease-in forwards;
        }

        #prize0.show::before {
            animation: fade-in 0.5s 6s ease-in alternate infinite;
        }

        #prize1.show {
            animation:
                fade-in 0s 3s forwards,
                drop-fade 0.5s 8s linear forwards;
        }

        #prize1.show::after {
            animation: fade-white 1s 3s ease forwards;
        }

        #prize2.show {
            animation:
                fade-in 0s 2s forwards,
                up 0.5s 8.5s ease-in forwards;
        }

        #prize2.show::after {
            animation: fade-white 1s 2s ease-in forwards;
        }

        #prize2.show::before {
            animation: fade-in 0.5s 6s ease-in alternate infinite;
        }

        .stars {
            position: absolute;
            left: calc((100% - 800px) / 2);
        }
    }

    .scale-down {
        animation: scale-down 0.5s 8.5s ease-in forwards;
    }

    @media only screen and (max-width: 720px), (max-height: 800px) {
        .prize-reveal {
            width: 340px;
            height: 85px;
            margin-bottom: -12px;

            p {
                margin-bottom: 14px;
            }
        }

        .ready-box {
            width: 320px;
            height: 154px;
            border-width: 15px;
        }

        h1 {
            font-size: 45px;
        }

        p {
            font-size: 18px;
        }
    }

    @media only screen and (max-height: 672px) {
        .ready-box {
            height: 134px;
        }

        h1 {
            font-size: 40px;
        }
    }
`;

export default StyleContainer;
