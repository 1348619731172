import { DRAW_BALL, RESET, TOGGLE_RANDOM, SET_PRIZE, REVEAL_BALL, UPDATE_TURN, SET_ALL } from '../actions';

const initialState = {
  balls: [],
  ballsPicked: [],
  numberOfBallChoices: [],
  whoPicked: [],
  pickIndex: -1,
  random: false,
  prizeChoices: [],
  gamePrize: '',
  pickTimeOut: 3
};

const checkBall = (ballsPicked, index) => {
  return ballsPicked.some(value => value === index);
};

const getPickIndex = (ballsPicked, total) => {
  let index = Math.floor(Math.random() * total);
  while(checkBall(ballsPicked, index)){
    index = (index + 1) % total;
  };
  return index;
};

export default function dispenser(state = initialState, action){
  switch(action.type){
    case RESET:
      return Object.assign({}, initialState);
    case DRAW_BALL:
      //IMPORTANT: numberOfBallChoices needs to be an array in order to trigger an update even if it has the same value
      const pickTimeOut = action.pickTimeOut || state.pickTimeOut;
      if(action.tie && action.numberOfBallChoices < 3)
        return Object.assign({}, state, { pickTimeOut, pickIndex: action.pickTimeOut ? getPickIndex(state.ballsPicked, state.numberOfBallChoices) : -2 });
      else
        return Object.assign({}, state, { pickTimeOut, numberOfBallChoices: [action.numberOfBallChoices], balls: [], ballsPicked: [], whoPicked: [], pickIndex: action.pickTimeOut ? Math.floor(Math.random() * action.numberOfBallChoices) : -2 });
    case REVEAL_BALL:
      let balls = [];
      if(Array.isArray(action.ballNumber)){
        balls = action.ballNumber;
      }else{
        balls = state.balls.slice();
        balls[action.ballIndex] = action.ballNumber;
      }
      return Object.assign({}, state, { balls, ballsPicked: state.ballsPicked.concat(action.ballIndex), whoPicked: state.whoPicked.concat(action.whoPicked), pickIndex: action.tie ? -2 : -1 });
    case TOGGLE_RANDOM:
      return Object.assign({}, state, { random: !state.random });
    case SET_PRIZE:
      return Object.assign({}, state, { prizeChoices: action.prizeChoices.slice(), gamePrize: action.gamePrize, pickTimeOut: action.pickTimeOut }); 
    case SET_ALL:
      return Object.assign({}, state, { gamePrize: action.payload.gamePrize }); 
    case UPDATE_TURN:
      if(state.pickIndex !== -2)
        return Object.assign({}, state, { pickIndex: -2, numberOfBallChoices: [3], balls: [], ballsPicked: [], whoPicked: [] });
      else
        return state;
    default:
      return state;
  }
}

