import StyleContainer from './style';
import { connect, useStore } from 'react-redux';
import { useEffect, useRef, useState, useContext } from 'react';
import Background from '../Background';
import ApngComponent from 'react-apng';
import {
    ChestParticles,
    ChestShake,
    ChestGlow,
    Star,
} from '../../assets/images';
import {
    SndChestFall,
    SndChestOpen,
    SndChestShake,
    SndFireworks0,
    SndFireworks1,
    SndMagicHarp,
    SndMatchReveal,
    SndPrizeMerge,
} from '../../assets/audios';
import { DataContext } from '../../contexts/DataContextContainer';
import { useTranslation } from 'react-i18next';

function Chest(props) {
    const { prizeChoices, gamePrize, numberOfBallChoices } = props;
    const prize = Number(gamePrize);
    const store = useStore();
    const partRef = useRef();
    const shakeRef = useRef();
    const glowRef = useRef();
    const canvasRef = useRef();
    const stars = useRef([]);
    const [showGlow, setShowGlow] = useState(false);
    const { currency, playerCurrency, currencies } = useContext(DataContext);
    const { t, i18n } = useTranslation();

    const playSndChestOpen = new Audio(SndChestOpen);
    const playSndFireworks0 = new Audio(SndFireworks0);
    const playSndFireworks1 = new Audio(SndFireworks1);
    const playSndMagicHarp = new Audio(SndMagicHarp);
    const playSndMatchReveal = new Audio(SndMatchReveal);
    const playSndPrizeMerge = new Audio(SndPrizeMerge);

    useEffect(() => {
        if (numberOfBallChoices[0] === 3) return;

        const shakeHandler = (frame) => {
            if (
                shakeRef.current &&
                frame >= shakeRef.current.apng.frames.length - 1
            ) {
                shakeRef.current.player.off('frame', shakeHandler);
                setShowGlow(true);
            }
        };

        const t1 = setInterval(() => {
            if (prize && shakeRef.current?.player) {
                clearInterval(t1);
                shakeRef.current.player.on('frame', shakeHandler);
                shakeRef.current.one();
                if (store.getState().menu.playSound) {
                    const playSndChestShake = new Audio(SndChestShake);
                    playSndChestShake.play();
                    setTimeout(() => playSndChestShake.play(), 500);
                }
            }
        }, 550);

        const t0 = setTimeout(() => {
            if (prize && store.getState().menu.playSound) {
                const playSndChestFall = new Audio(SndChestFall);
                playSndChestFall.play();
            }
        }, 250);

        return () => {
            clearTimeout(t0);
            clearInterval(t1);
        };
    }, [prize, numberOfBallChoices, store]);

    useEffect(() => {
        if (!prize || numberOfBallChoices.length) return;

        let canvas, ctx, req, timer;

        const img = new Image();
        img.src = Star;

        const reset = () => {
            return {
                x: canvas.width / 2,
                y: 250,
                speedX: 5 - Math.random() * 10,
                speedY: 5 - Math.random() * 10,
                scale: Math.random() * 0.5,
                width: 119,
                height: 119,
                alpha: Math.random() * 2,
            };
        };

        const draw = () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.globalAlpha = 1;
            for (let i = 0; i < stars.current.length; i++) {
                if (stars.current[i].alpha <= 0) {
                    continue;
                }
                stars.current[i].x += stars.current[i].speedX;
                stars.current[i].y += stars.current[i].speedY;
                stars.current[i].alpha = Math.max(
                    0,
                    stars.current[i].alpha - 0.02,
                );
                let width = stars.current[i].width * stars.current[i].scale;
                let height = stars.current[i].height * stars.current[i].scale;
                ctx.save();
                ctx.globalAlpha = stars.current[i].alpha;
                ctx.drawImage(
                    img,
                    0,
                    0,
                    stars.current[i].width,
                    stars.current[i].height,
                    stars.current[i].x - width / 2,
                    stars.current[i].y - height / 2,
                    width,
                    height,
                );
                ctx.restore();
            }
            req = requestAnimationFrame(draw);
        };

        const partHandler = (frame) => {
            if (!partRef.current) return;
            if (frame === partRef.current.apng.frames.length - 10) {
                canvas = canvasRef.current;
                ctx = canvas.getContext('2d');
                while (stars.current.length < 150) {
                    stars.current.push(reset());
                }
                if (store.getState().menu.playSound)
                    setTimeout(() => playSndMatchReveal.play(), 1000);
                requestAnimationFrame(draw);
            } else if (frame >= partRef.current.apng.frames.length - 1) {
                partRef.current.player.off('frame', partHandler);
                if (store.getState().menu.playSound) {
                    setTimeout(() => playSndPrizeMerge.play(), 2000);
                    setTimeout(() => playSndPrizeMerge.play(), 2750);
                }
            }
        };

        const glowHandler = (frame) => {
            if (
                glowRef.current &&
                frame >= glowRef.current.apng.frames.length - 1
            ) {
                glowRef.current.player.off('frame', glowHandler);
                playParticles();
            }
        };

        const playParticles = () => {
            if (partRef.current.player) {
                partRef.current.player.on('frame', partHandler);
                partRef.current.one();
                if (store.getState().menu.playSound) {
                    playSndMagicHarp.play();
                    playSndFireworks0.play();
                    setTimeout(() => playSndFireworks1.play(), 1750);
                }
            } else {
                timer = setTimeout(() => {
                    playParticles();
                }, 500);
            }
        };

        if (showGlow) {
            glowRef.current.player.on('frame', glowHandler);
            glowRef.current.one();
            if (store.getState().menu.playSound)
                setTimeout(() => playSndChestOpen.play(), 500);
        }

        return () => {
            cancelAnimationFrame(req);
            clearTimeout(timer);
        };
    });

    let scale = window.innerHeight / 800;

    if (numberOfBallChoices.length || !prizeChoices.length) return null;

    return (
        <StyleContainer
            scale={scale}
            // className={'full-height ' + (showGlow && 'fade-out')}
            // style={{ visibility: prize ? 'visible' : 'hidden' }}
        >
            <Background />
            <div className={`prize-reveal ${i18n.language}`}>
                <p>{t('prizeReveal')}</p>
            </div>
            <div className={`ready-box ${i18n.language}`}>
                <h1>{t('ready')}</h1>
                <p>{t('begin')}</p>
            </div>
            <div className={'container ' + (showGlow && 'scale-down')}>
                <div
                    className={
                        'chest ' + (showGlow || !prize ? 'hide' : 'chest--drop')
                    }
                >
                    <ApngComponent ref={shakeRef} src={ChestShake} rate={1} />
                </div>
                <div
                    className={'chest ' + (!showGlow ? 'hide' : 'chest--glow')}
                >
                    <ApngComponent ref={glowRef} src={ChestGlow} rate={1} />
                </div>
                <div className={'glow ' + (!showGlow ? 'hide' : 'fade-in')}>
                    <svg width="452" height="400">
                        <linearGradient
                            id="gradient"
                            x1="0"
                            x2="0"
                            y1="0"
                            y2="1"
                        >
                            <stop
                                offset="0%"
                                stopColor="#ff0"
                                stopOpacity="0"
                            />
                            <stop
                                offset="100%"
                                stopColor="#ff0"
                                stopOpacity="0.35"
                            />
                        </linearGradient>
                        <polygon
                            points="0,0 452,0 315,390 130,400"
                            fill="url(#gradient)"
                        />
                    </svg>
                </div>
                <div
                    id="prize0"
                    className={'prize-box ' + (!showGlow ? 'hide' : 'show')}
                >
                    {currencies.current[currency.current].draw(
                        prizeChoices[0],
                        playerCurrency.current,
                    )}
                </div>
                <div
                    id="prize1"
                    className={'prize-box ' + (!showGlow ? 'hide' : 'show')}
                >
                    {currencies.current[currency.current].draw(
                        prizeChoices[1],
                        playerCurrency.current,
                    )}
                </div>
                <div
                    id="prize2"
                    className={'prize-box ' + (!showGlow ? 'hide' : 'show')}
                >
                    {currencies.current[currency.current].draw(
                        gamePrize,
                        playerCurrency.current,
                    )}
                </div>
                <ApngComponent
                    ref={partRef}
                    className={'particles ' + (!showGlow ? 'hide' : 'fade-in')}
                    src={ChestParticles}
                    rate={0.75}
                />
                <canvas
                    ref={canvasRef}
                    className="stars"
                    width={800}
                    height={800}
                />
            </div>
        </StyleContainer>
    );
}

const mapState = (state) => {
    return {
        prizeChoices: state.dispenser.prizeChoices,
        gamePrize: state.dispenser.gamePrize,
        numberOfBallChoices: state.dispenser.numberOfBallChoices,
    };
};

export default connect(mapState)(Chest);
