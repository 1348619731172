import styled from '@emotion/styled';
import { BgGrad, BgBuyInBet, BgBuyInPrize, btnMinus, btnAdd, btnPurple } from '../../assets/images';

const StyleContainer = styled.div`
  width: 100%;
  height: calc(100% - 80px);
  line-height: 1.2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  container-type: size;

  .hide {
    visibility: hidden;
  }

  .btn {
    cursor: pointer;
  }

  .btn:active {
    transform: scale(0.95);
  }

  .buy-in-bg {
    width: 420px;
    height: 597px;
    border: 20px solid transparent;
    border-image-source:url(${BgGrad});  
    border-image-repeat: stretch;
    border-image-slice: 20 fill;
    font-weight: 900;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    flex-shrink: 0;

    h1 {
      font-weight: 900;
      color: #fff;
      font-size: 33px;
      text-align: center;
      text-shadow: 0 3px rgba(1, 44, 98, 0.5);
      margin: 14px 5px 5px;
      line-height: 1.1;
    }

    h2 {
      font-weight: 900;
      color: #fff;
      font-size: 24px;
      text-align: center;
      text-shadow: 0 3px rgba(1, 44, 98, 0.5);
      margin: 5px;
    }

    h3 {
      font-weight: 900;
      font-size: 30px;
      text-align: center;
      color: #ffd912;
      text-shadow: 0 3px rgba(1, 44, 98, 0.5);
      margin: 0;
    }

    .buy-in-prize {
      margin: 10px auto;
      width: 339px;
      height: 113px;
      background: url(${BgBuyInPrize}) center / contain no-repeat;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 20px;
      font-size: 46px;
      font-weight: 700;
      color: #ffd800;
      text-align: center;
      text-shadow: 1px -1px 1px #c9800c, 0 2px 0 #b36a06, 0 2px 4px #000;
    }

    .bet {
      width: 100%;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
    }

    .btn--minus {
      width: 100px;
      height: 100px;
      margin: -10px;
      background: url(${btnMinus}) center / contain no-repeat;
    }

    .btn--add {
      width: 100px;
      height: 100px;
      margin: -10px;
      background: url(${btnAdd}) center / contain no-repeat;
    }

    .buy-in-bet {
      width: 276px;
      height: 132px;
      margin: 0 -25px;
      background: url(${BgBuyInBet}) center / contain no-repeat;
      font-size: 30px;
      text-align: center;
      text-shadow: 1px 1px rgba(0, 74, 29, 0.6);
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
    }

    .btn--purple {
      position: absolute;
      bottom: -80px;
      width: 355px;
      height: 128px;
      background: url(${btnPurple}) center / contain no-repeat;
      font-size: 30px;
      text-shadow: 1px 1px rgba(51, 0, 51, 0.6);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @container (max-width: 460px) {
    .buy-in-bg {
      transform: scale(0.75);
    }
  }

  @container (max-height: 460px) {
    .buy-in-bg {
      transform: scale(0.7);
    }
  }
`;

export default StyleContainer;