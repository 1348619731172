import styled from '@emotion/styled';
import { Dispenser } from '../../assets/images';

const StyleContainer = styled.div`
  width: 556px;
  height: 101px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  transform-origin: bottom center;

  .dispenser {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateY(101px);

    .container {
      width: 360px;
      height: 150px;
      left: 98px;
      bottom: 52px;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      overflow: hidden;
    }
  }

  .dispenser::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(${Dispenser}) center / contain no-repeat;
  }

  .ball-highway {
    position: absolute;
    left: -77px;
    width: 450px;
    height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .balls {
    position: absolute;
    top: -66px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
  }

  @media only screen and (max-height: 830px) {
    transform: scale(0.9);
  }

  @media only screen and (max-height: 750px) {
    transform: scale(0.8);
  }

  @media only screen and (max-height: 670px) {
    transform: scale(0.75);
  }
`;

export default StyleContainer;