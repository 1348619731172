import StyleContainer from './style';
import { connect, useStore } from 'react-redux';
import { toggleRandom, pickBall } from '../../actions';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

function RandomPlay(props) {
  const { numberOfBallChoices, random, toggleRandom, pickBall } = props;
  const params = new URLSearchParams(window.location.search.slice(1));
  const store = useStore();
  const isEnabled = useRef(false);
  const players = store.getState().cards.players;
  const currentTurn = store.getState().cards.currentTurn;
  const { t } = useTranslation();

  useEffect(() => {
    if(numberOfBallChoices){
      isEnabled.current = false;
      setTimeout(() => {
        isEnabled.current = true;
      }, 1250);
    }
  }, [numberOfBallChoices]);

  useEffect(() => {
    const pickIndex = store.getState().dispenser.pickIndex;
    const ballsPicked = store.getState().dispenser.ballsPicked;
    if(!ballsPicked.length && random && players[0].playerTurn === currentTurn && isEnabled.current && pickIndex >= 0){
      pickBall(pickIndex, store.getState().cards.tieBreaker.length > 0, store.getState().cards.players[0].playerSessionId);
    }
  });

  const handleRandom = () => {
    toggleRandom();
  }
  
  if(numberOfBallChoices.length && params.get('style') !== 'TURBODRAW'){
    return(
      <StyleContainer>
        <div className={'btn pill ' + (random ? 'pill--on' : 'pill--off')} onClick={handleRandom}>{t(random ? 'on' : 'off')}</div>
        <div className='text'>{t('random')}</div>
      </StyleContainer>
    );
  }else{
    return null;
  }
}

const mapDispatch = dispatch => {
  return {
    toggleRandom: () => dispatch(toggleRandom()),
		pickBall: (index, tie, playerSessionId) => dispatch(pickBall(index, tie, playerSessionId)),
  }
}

const mapState = state => {
	return {
		random: state.dispenser.random,
		numberOfBallChoices: state.dispenser.numberOfBallChoices,
	};
}

export default connect(mapState, mapDispatch)(RandomPlay);
