import styled from '@emotion/styled';
import {
    Screen1,
    Screen2,
    Screen3,
    Screen4,
    Screen5,
    Screen6,
    Screen1PT,
    Screen2PT,
    Screen3PT,
    Screen4PT,
    Screen5PT,
    Screen6PT,
    Screen1ES,
    Screen2ES,
    Screen3ES,
    Screen4ES,
    Screen5ES,
    Screen6ES,
} from '../../assets/images';

const screen1Localized = {
    en: Screen1,
    pt: Screen1PT,
    es: Screen1ES,
};

const screen2Localized = {
    en: Screen2,
    pt: Screen2PT,
    es: Screen2ES,
};

const screen3Localized = {
    en: Screen3,
    pt: Screen3PT,
    es: Screen3ES,
};

const screen4Localized = {
    en: Screen4,
    pt: Screen4PT,
    es: Screen4ES,
};

const screen5Localized = {
    en: Screen5,
    pt: Screen5PT,
    es: Screen5ES,
};

const screen6Localized = {
    en: Screen6,
    pt: Screen6PT,
    es: Screen6ES,
};

export const StyleContainer = styled.div`
    position: absolute;
    background-color: rgba(0, 0, 0, 0.9);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -54px;
    width: 100%;

    @media only screen and (max-width: 720px) {
        width: auto;
    }
`;
export const InstructionFirstScreen = styled.div`
    background-size: 100% 100%;
    height: 500px;
    width: 450px;
`;

export const InstructionScreen1 = styled.div`
    background-image: url(${(props) =>
        props.lang ? screen1Localized[props.lang] : Screen1});
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    width: 80%;
    height: 80%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

export const InstructionScreen2 = styled.div`
    background-image: url(${(props) =>
        props.lang ? screen2Localized[props.lang] : Screen2});
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    width: 80%;
    height: 80%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

export const InstructionScreen3 = styled.div`
    background-image: url(${(props) =>
        props.lang ? screen3Localized[props.lang] : Screen3});
    background-size: 155%;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    width: 68%;
    height: 80%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

export const InstructionScreen4 = styled.div`
    background-image: url(${(props) =>
        props.lang ? screen4Localized[props.lang] : Screen4});
    background-size: 155%;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    width: 68%;
    height: 80%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

export const InstructionScreen5 = styled.div`
    background-image: url(${(props) =>
        props.lang ? screen5Localized[props.lang] : Screen5});
    background-size: 154%;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    width: 68%;
    height: 80%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

export const InstructionScreen6 = styled.div`
    background-image: url(${(props) =>
        props.lang ? screen6Localized[props.lang] : Screen6});
    background-size: 157%;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    width: 68%;
    height: 80%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

export const InstructionNextBtnContainer = styled.div`
    position: absolute;
    bottom: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;
export const InstructionNextBtn = styled.div`
    background-color: green;
    border-radius: 6px;
    color: rgb(255, 255, 255);
    padding: 8px 22px;
    text-align: center;
    border: 1px solid darkgreen;
    font-size: 17px;
    cursor: pointer;
    &:active {
        transform: scale(0.95);
    }
`;

export const InstructionClose = styled.div`
    position: absolute;
    top: 70px;
    right: 60px;
    color: #fff;
    font-size: 26px;
    z-index: 10;
`;
