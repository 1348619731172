import StyleContainer from './style';
import { connect, useStore } from 'react-redux';

function Label(props) {
  const { id, name, opacity, currentTurn, winnerId, gamePrize } = props;
  const store = useStore();

  const isActive = () => {
    return store.getState().cards.players[id].playerTurn === currentTurn && gamePrize > 0 && !winnerId;
  };
  
  return(
    <StyleContainer id={id} active={isActive()} style={{ opacity: opacity || 1 }}>
      <div>{name}</div>
      {isActive() && <div className='dots'></div>}
    </StyleContainer>
  );
}

const mapState = state => {
	return {
		currentTurn: state.cards.currentTurn,
    winnerId: state.cards.winnerId,
    gamePrize: state.dispenser.gamePrize
	};
}

export default connect(mapState)(Label);
