import StyleContainer from './style';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

function Player(props) {
  const { id, players } = props;
  const { t } = useTranslation();

  const playerExist = id <= players.length - 1;


  if(id < 1){
    return(
      <StyleContainer className='self'>
        <div className='player-name'>
          <div>{playerExist ? players[id].playerAlias : t('waiting')}</div>
          {!playerExist && <div className='dots'></div>}
        </div>
      </StyleContainer>
    );
  }else{
    return(
      <StyleContainer className='opponent'>
        <div className='photo-box'>
          <div className='silhouette'>{playerExist ? '' : '?'}</div>
        </div>
        <div className='player-name'>
          <div>{playerExist ? players[id].playerAlias : t('waiting')}</div>
          {!playerExist && <div className='dots'></div>}
        </div>
      </StyleContainer>
    );
  }
}

const mapState = state => {
	return {
		players: state.cards.players
	};
}

export default connect(mapState)(Player);
