import StyleContainer from './style';
import { connect } from 'react-redux';

function PlayerStatus(props) {
  const { players } = props;

  if(players.length < 3) return null;

  const setLose = new Set();
	players[1].predictiveTiles.forEach(item => setLose.add(item));
  players[2].predictiveTiles.forEach(item => setLose.add(item));
	const aDanger = Array.from(setLose);

  const GetNumbers = (props) => {
    const double = props.list.length > 9;

    props.list.sort((a, b) => a - b);

    return(
      <div className='numbers'>
        <div className={'text ' + (double ? 'text--double' : 'text--single')} style={{ backgroundColor: props.bgColor }}>{props.list.slice(0,8).join(', ')}</div>
        {double && <div className='text text--double' style={{ backgroundColor: props.bgColor }}>{props.list.slice(8).join(', ')}</div>}
      </div>
    );
  }

  return(
    <StyleContainer>
      {players[0].predictiveTiles.length > 0 && 
        <div className='row'>
          <div className='win'></div>
          <GetNumbers list={players[0].predictiveTiles} bgColor={'#00a001'} />
        </div>
      }
      {aDanger.length > 0 && 
        <div className='row'>
          <div className='danger'></div>
          <GetNumbers list={aDanger} bgColor={'#de251d'} />
        </div>
      }
    </StyleContainer>
  );
}

const mapState = state => {
  return {
    players: state.cards.players,
	};
}

export default connect(mapState)(PlayerStatus);
