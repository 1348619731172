import styled from '@emotion/styled';

const StyleContainer = styled.div`
  position: absolute;
  top: -45px;
  width: 300px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  font-size: 20px;
  color: #f4e20f;
  text-shadow: rgba(0, 0, 0, 0.3) 0px 2px 0px;
  pointer-events: none;
  z-index: 2;

  &.me {
    top: -90px;
  }

  &::before {
    content: '';
    position: absolute;
    height: 100%;
    background: linear-gradient(90deg, rgba(0,20,39,0), rgba(0,20,39,0.5), rgba(0,20,39,0.5), rgba(0,20,39,0));
    border-width: 1px;
    border-style: solid; 
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, rgba(255, 255, 255, 0), #fff, rgba(255, 255, 255, 0));
    z-index: -1;
  }

  &.fade-in {
    opacity: 0;
    animation: fade-in 0.25s ${props => props.tie ? 0 : 1}s ease-in forwards;
  }

  &.fade-in::before {
    width: 0%;
    animation: expand 0.25s ${props => props.tie ? 0 : 1}s ease-in forwards;
  }

  &.fade-out {
    animation: fade-out 0.25s ease-out forwards;
  }

  &.fade-out::before {
    animation: shrink 0.25s ease-out forwards;
  }

  @keyframes fade-in {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes expand {
    0% { width: 0; }
    100% { width: 100%; }
  }

  @keyframes shrink {
    0% { width: 100%; }
    100% { width: 0; }
  }

  @keyframes fade-out {
    0% { opacity: 1; transform: translateY(0); }
    100% { opacity: 0; transform: translateY(20px); }
  }

  .dots {
    width: 20px;
  }

  .dots::before {
    content: '';
    animation: dots 2s linear infinite;
  }

  @keyframes dots {
    0% { content: ''; }
    25% { content: '.'; }
    50% { content: '..'; }
    75% { content: '...'; }
  }
`;

export default StyleContainer;