import styled from '@emotion/styled';
import { IconWin, IconDanger } from '../../assets/images';

const StyleContainer = styled.div`
  position: absolute;
  top: 410px;
  width: 100%;
  padding: 0 26px;
  display: inline;

  > .row {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .text {
      font-weight: 700;
      color: #fff;
      padding: 2px 8px;
      margin-left: 5px;
      animation: pulse 0.75s ease infinite;
    }
  }
  
  .win {
    width: 37px;
    height: 42px;
    background: url(${IconWin}) center / contain no-repeat;
    transform: translate(-1px, -4px);
  }

  .danger {
    width: 36px;
    height: 38px;
    background: url(${IconDanger}) center / contain no-repeat;
  }

  .numbers {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  @keyframes pulse {
    33% { opacity: 1; }
    66% { opacity: 1; }
    100% { opacity: 0.5; }
  }

  .text--single {
    font-size: 18px;
    margin-top: 5px;
    border-radius: 13px;
  }

  .text--double {
    font-size: 12px;
    margin-top: 1px;
    border-radius: 10px;
  }
`;

export default StyleContainer;