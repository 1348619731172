import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

const params = new URLSearchParams(window.location.search.slice(1));

i18next.use(initReactI18next).use(HttpApi).init({
  lng: params.get('language')?.toLowerCase(),
  fallbackLng: 'en'
});

export default i18next;