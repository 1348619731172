import styled from '@emotion/styled';

const StyleContainer = styled.div`
  width: 360px;
  height: calc(100% - 54px);
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  .btn {
    cursor: pointer;
  }
  
  .btn:active {
    opacity: 0.25; 
  }
`;

export default StyleContainer;