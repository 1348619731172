import styled from '@emotion/styled';
import { CircleGreen, CircleRed, Ball, Shockwave } from '../../assets/images';

const StyleContainer = styled.div`
  width: ${props => props.cardId && !props.complete ? 26 : 30}px;
  height: ${props => props.cardId && !props.complete ? 26 : 30}px;
  margin: 1px;
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-shadow: none;
  opacity: ${props => props.complete && !props.winner ? 0.5 : 1};
  z-index: ${props => props.latest ? 1 : 0};
  animation: ${props => props.flash ? 'pulse 0.75s 0.5s ease infinite' : 'none'};

  @keyframes pulse {
    33% { opacity: 0.7; }
    67% { opacity: 0.7; }
    100% { opacity: 1; }
  }

  .text {
    font-weight: 700;
    font-size: ${props => props.cardId && !props.complete ? 17 : 19}px;
    color: ${props => !props.latest && props.drawn ? '#fff' : !props.flash ? '#242e42' : props.cardId ? '#de251d' : '#00a001'};
    z-index: ${props => props.complete ? 1 : 0};
  }

  .full {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .circle {
    border-radius: 50%;
    transform: scale(${props => props.complete ? 1 : 0.75});
    animation: ${props => !props.latest ? 'none' : 'show 0.5s forwards, ' + (props.winner && !props.complete && 'scale-circle 0.5s 1.5s 2 alternate')};
    overflow: hidden;
  }

  @keyframes show {
    0% { opacity: 0; }
    99% { opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes scale-circle {
    100% { transform: scale(1.2); }
  }

  .circle--green {
    background: url(${CircleGreen}) center / contain;
  }

  .circle--red {
    background: url(${CircleRed}) center / contain;
  }

  .ball {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: url(${Ball}) 50% ${props => props.isPlayer ? 100 : 0}% / cover no-repeat;
    transform: scale(0);
    animation: scale 0.5s ease, x 0.5s ease-in forwards, y 0.5s ease-in forwards;
    overflow: hidden;
  }

  @keyframes scale {
    0% { transform: scale(1); }
    50% { transform: scale(2); }
    100% { transform: scale(0.25); }
  }

  @keyframes x {
    50% { left: calc(-300%); }
    100% { left: calc(-50%); }
  }

  @keyframes y {
    to { top: calc(-50%); }
  }

  .circle--shine::before {
    content: '';
    position: absolute;
    width: 300%;
    height: 100%;
    left: -200%;
    top: 0;
    animation: shine 0.5s 1s linear;
  }

  .circle--red.circle--shine::before {
    background: linear-gradient(135deg, rgba(255,132,65,0) 33%, rgba(255,132,65,1) 50%, rgba(255,132,65,0) 67%);
  }

  .circle--green.circle--shine::before {
    background: linear-gradient(135deg, rgba(127,255,81,0) 33%, rgba(127,255,81,1) 50%, rgba(127,255,81,0) 67%);
  }

  @keyframes shine {
    100% { left: 0%; }
  }

  canvas {
    position: absolute;
    left: calc((100% - 200px) / 2);
    top: calc((100% - 200px) / 2);
  }

  .shockwave {
    position: absolute;
    left: calc((${props => props.cardId ? 26 : 30}px - 570px) / 2);
    top: calc((${props => props.cardId ? 26 : 30}px - 570px) / 2);
    width: 570px;
    height: 570px;
    background: url(${Shockwave}) center 570px / cover no-repeat;
    animation: shock 1.75s 1.5s steps(30);
    pointer-events: none;
  }
  
  @keyframes shock {
    100% { background-position: 0 -16530px; }
  }
`;

export default StyleContainer;